import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { ENTITIES_TRACKER_SCREEN, getPhonesOnlyNumbers } from '@/utils';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Truncate from '@/components/Truncate';
import Card from '@/components/Card';
import WhatsappAndACButtons from '@/components/WhatsApp/WhatsappAndACButtons';
import { useWhatsappPopoverContext } from '@/contexts/whatsappPopover';

DetailsWhatsappContactItem.defaultProps = {
  entity: {},
  entityType: '',
  isLastItem: false
};

DetailsWhatsappContactItem.propTypes = {
  entity: PropTypes.object,
  entityType: PropTypes.string,
  isLastItem: PropTypes.bool
};

function DetailsWhatsappContactItem({
  entity,
  entityType,
  isLastItem
}) {
  const {
    setSelectedEntity, entityType: fullEntityType
  } = useWhatsappPopoverContext();

  const { contact } = entity;
  const contactPhones = getPhonesOnlyNumbers(contact);
  // Array vazio para manter layout consistente em contatos sem telefone
  const phones = contactPhones?.length ? contactPhones : [''];

  return (
    <Card className='whatsapp-contact-item p-1 border-0 bg-lighter-gray mb-2'>
      <div key={entity.id} className='d-flex'>
        <div className='d-flex bg-light-gray rounded p-2 align-self-baseline'>
          <Icon name={entityType} />
        </div>
        <div className='ms-2 flex-fill'>
          <Truncate as='div' className='width-5 text-darker-gray fw-bold mt-2 mb-1'>
            {entity.name}
          </Truncate>
          {phones?.map((phone, index) => (
            <Row key={`${index}_${phone}`}>
              {index ? <hr className='my-2 mb-4 text-light-gray opacity-50' /> : null}
              <Col sm={4} className='mt-2 d-flex text-dark-gray'>
                {phone}
              </Col>
              {
                phone
                  ? (
                    <Col sm={8} className='d-flex ms-n4'>
                      <WhatsappAndACButtons
                        name={entity.name}
                        phone={phone}
                        entity={entityType}
                        entityId={entity.id}
                        screen={ENTITIES_TRACKER_SCREEN[fullEntityType]}
                      />
                    </Col>
                  )
                  : null
              }
            </Row>
          ))}
          <Row>
            <div className='text-start ms-n5 mt-2 text-primary fw-bold'>
              <Button
                variant='link'
                className='text-primary fw-bold text-nowrap'
                onClick={() => setSelectedEntity({ ...entity, type: entityType })}
              >
                <Icon name='plus' />
                Adicionar telefone
              </Button>
            </div>
            {isLastItem ? null : <hr className='my-3 text-light-gray opacity-50' />}
          </Row>
        </div>
      </div>
    </Card>
  );
}

export default DetailsWhatsappContactItem;
