import React from 'react';
import DetailsWhatsappContactItem from '@/feature/whatsapp/DetailsWhatsappContactItem';

function ContactListPerson({ entity, relatedEntity }) {
  return (
    <>
      <DetailsWhatsappContactItem
        entityType='person'
        entity={entity}
        isLastItem={!relatedEntity}
        entityScreen='Pessoa'
      />
      {
        relatedEntity &&
        <DetailsWhatsappContactItem
          entityType='organization'
          entity={relatedEntity}
          isLastItem
        />
      }
    </>
  );
}

export default ContactListPerson;
