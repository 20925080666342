import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { checkoutPath } from '@/routes';
import { useAuth } from '@/lib/auth';
import { useNextVisibleTime } from '@/hooks';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { parseDate } from '@/date';

const accountExpirationWarningKey = 'headerAccountExpirationWarning';

export function useHeaderAccountExpirationWarning(currentPlanData) {
  const noHeaderWarningPrefix = '/settings/account/current_plan';

  const location = useLocation();
  const { user } = useAuth();
  const isAdmin = user.admin;
  const { fiveDaysUntilBlock, expiresAt } = user.account;
  const daysToDisplayAgain = 3;
  const {
    isWarningVisible,
    handleWarningClose
  } = useNextVisibleTime(accountExpirationWarningKey, daysToDisplayAgain);
  const isTrial = currentPlanData?.subscriptionType === 'trial';

  const showWhenExpiredOrExpiringToday = useMemo(() => {
    const parsedExpirationDate = parseDate(expiresAt);
    const now = new Date();
    const noon = 12;
    parsedExpirationDate.setHours(noon, 0, 0, 0);

    return now > parsedExpirationDate;
  }, [expiresAt]);

  const isPathExcluded = location.pathname.startsWith(noHeaderWarningPrefix);
  const shouldShowWarning =
    !fiveDaysUntilBlock && (isAdmin && showWhenExpiredOrExpiringToday);

  if (shouldShowWarning && isWarningVisible && !isPathExcluded && !isTrial) {
    return { show: true, onClose: handleWarningClose };
  }

  return { show: false };
}

function HeaderAccountExpirationWarning({ onClose }) {
  const { user } = useAuth();

  const isAdmin = user.admin;
  const { paymentTypeCode } = user.account;

  const isBankSlip = paymentTypeCode === 'bank_slip';
  const buttonText = isBankSlip ? 'Gerar 2ª via boleto' : 'Alterar forma de pagamento';
  const message = isBankSlip
    ? `Caso já tenha pago o boleto e ele ainda esteja em
    compensação bancária, desconsidere essa mensagem.`
    : 'Regularize sua assinatura para continuar usando o Agendor.';

  return (
    <div className='p-4 bg-light-yellow'>
      <div className='p-0 d-flex align-items-center'>
        <div className='row w-100'>
          <div className='ms-6 col-auto d-flex align-items-center'>
            <Icon name='info' size='sm' />
          </div>
          <div className='col d-flex flex-column justify-content-center'>
            <h4 className='mb-1 text-medium fw-bold'>Pagamento não identificado</h4>
            <div className='text-dark-gray text-small'>{message}</div>
          </div>
          <div className='col-auto d-flex flex-column align-items-end'>
            {isAdmin && (
              <>
                {isBankSlip && (
                  <Button
                    href={checkoutPath()}
                    variant='outline-dark-gray'
                    className='w-100'
                    size='sm'
                  >
                    Pagar com Cartão de Crédito
                  </Button>
                )}
                <Button
                  href={checkoutPath()}
                  variant='outline-dark-gray'
                  className='mt-2 w-100'
                  size='sm'
                >
                  {buttonText}
                </Button>
              </>
            )}
          </div>
          <div className='col-auto d-flex flex-column justify-content-center cursor-pointer'>
            <Icon
              name='close'
              size='md'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderAccountExpirationWarning;
