import React from 'react';
import { useLocation } from 'react-router-dom';

import { checkoutPath } from '@/routes';
import { useAuth } from '@/lib/auth';

import Button from '@/components/Button';
import Icon from '@/components/Icon';

export function useHeaderAccountBlockedWarning(currentPlanData) {
  const noHeaderWarningPrefix = '/settings/account/current_plan';

  const location = useLocation();
  const { user } = useAuth();
  const { blockedAccess } = user.account;
  const { admin } = user;
  const isTrial = currentPlanData?.subscriptionType === 'trial';

  const isPathExcluded = location.pathname.startsWith(noHeaderWarningPrefix);

  if (blockedAccess && !isPathExcluded && admin && !isTrial) {
    return { show: true, onClose: () => {} };
  }

  return { show: false };
}

function HeaderAccountBlockedWarning() {
  return (
    <div className='p-4 bg-danger bg-opacity-10'>
      <div className='p-0 d-flex align-items-center'>
        <div className='row w-100 align-items-center'>
          <div className='ms-6 col-auto d-flex align-items-center text-danger'>
            <Icon name='alert' size='sm' />
          </div>
          <div className='col d-flex flex-column justify-content-center'>
            <h4 className='mb-1 text-medium fw-bold'>Conta bloqueada por falta de pagamento</h4>
            <div className='text-dark-gray text-small'>
              Renove sua assinatura para continuar utilizando o Agendor.
            </div>
          </div>
          <div className='col-auto d-flex flex-column align-items-end'>
            <Button
              href={checkoutPath()}
              className='w-100'
              size='sm'
            >
              Renovar assinatura
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderAccountBlockedWarning;
