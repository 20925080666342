import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import classnames from 'classnames';
import LoadSpinner from '@/components/LoadSpinner';
import Modal from '@/components/Modal';
import EmailActivity from '@/components/Email/EmailActivity';

EmailHistoryModal.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    createdBy: PropTypes.shape({
      name: PropTypes.string
    }),
    dueAt: PropTypes.string,
    createdAt: PropTypes.string,
    text: PropTypes.string,
    finished: PropTypes.bool,
    finishedAt: PropTypes.string,
    finishedBy: PropTypes.shape({
      name: PropTypes.string
    }),
    assignedUsers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.bool,
      name: PropTypes.string,
      avatarUrl: PropTypes.string
    }))
  })),
  isFetchingActivities: PropTypes.bool,
  isFetchingNextPageActivities: PropTypes.bool,
  fetchNextPageActivities: PropTypes.func,
  hasNextPageActivities: PropTypes.bool,
  onDeleteActivity: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool
};

EmailHistoryModal.defaultProps = {
  activities: [],
  isFetchingActivities: false,
  isFetchingNextPageActivities: false,
  fetchNextPageActivities: () => {},
  hasNextPageActivities: false,
  onDeleteActivity: () => {},
  onHide: () => {},
  show: false
};

const renderHistoryEmpty = () => (
  <div className='text-center'>
    <h4 className='mb-2'>Nenhum e-mail registrado</h4>
  </div>
);

function EmailHistoryModal({
  entityId, entityType, activities, isFetchingActivities, isFetchingNextPageActivities,
  fetchNextPageActivities, hasNextPageActivities, onDeleteActivity, show, onHide
}) {
  const isLoadingOrFetching = isFetchingActivities || isFetchingNextPageActivities;

  if (!activities.length && !isLoadingOrFetching) {
    return renderHistoryEmpty();
  }

  return (
    <Modal
      show={show}
      size='xl'
      backdrop='static'
      bodyClassName='p-8'
      restoreFocus={false}
      onHide={onHide}
    >
      <h3 className='mb-4'>Conversa de e-mail</h3>
      <InfiniteScroll
        scrollableTarget='entity-modal'
        dataLength={activities?.length}
        next={fetchNextPageActivities}
        hasMore={hasNextPageActivities}
        scrollThreshold={0.95}
        className='overflow-hidden d-flex flex-column flex-grow-1 flexible-height'
        loader={
          <LoadSpinner size='md' className={classnames(
            'text-center',
            'mx-auto',
            'text-dark-gray',
            'opacity-100'
          )}/>
        }
      >
        {activities.map((activity) => (
          <div className='mb-3' key={activity.id}>
            <EmailActivity
              entityId={entityId}
              entityType={entityType}
              activity={activity}
              onDelete={onDeleteActivity}
            />
          </div>
        ))}
      </InfiniteScroll>
    </Modal>
  );
}

export default EmailHistoryModal;
