import React from 'react';
import DetailsWhatsappContactItem from '@/feature/whatsapp/DetailsWhatsappContactItem';

function ContactListOrganization({ entity, relatedEntity }) {
  return (
    <>
      <DetailsWhatsappContactItem
        entity={entity}
        entityType='organization'
        isLastItem={relatedEntity?.length === 0}
        entityScreen='Empresa'
      />

      {
        relatedEntity?.map((entityData, index) => (
          <DetailsWhatsappContactItem
            key={entityData.id}
            entityType='person'
            entity={entityData}
            isLastItem={index === relatedEntity.length - 1}
          />
        ))
      }
    </>
  );
}

export default ContactListOrganization;
