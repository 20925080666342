import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import compact from 'lodash/compact';
import set from 'lodash/set';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Tooltip from '@/components/Tooltip';
import Authorization from '@/components/Authorization';

const propTypes = {
  as: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  isCustomerSelect: PropTypes.bool,
  isSelect: PropTypes.bool,
  isCustomField: PropTypes.bool,
  isDatePicker: PropTypes.bool,
  hasOwnerUser: PropTypes.bool,
  disabled: PropTypes.bool,
  disableLabelClick: PropTypes.bool,
  tooltipString: PropTypes.string,
  data: PropTypes.object,
  policy: PropTypes.func,
  hideEdit: PropTypes.bool,
  hidePopover: PropTypes.func
};

const defaultProps = {
  onConfirm: () => { },
  onCancel: () => { },
  policy: () => ({ effect: 'allow', reason: '' }),
  className: '',
  isCustomerSelect: false,
  isSelect: false,
  isCustomField: false,
  isDatePicker: false,
  hideEditButtonWhenEmpty: true,
  disabled: false,
  disableLabelClick: false,
  hideEdit: false,
  tooltipString: '',
  hidePopover: () => { }
};

const EditInPlace = React.forwardRef((props, ref) => {
  const {
    name,
    as: Component,
    value,
    onConfirm,
    onCancel,
    field,
    isDatePicker,
    children,
    className,
    isSelect,
    isCustomField,
    options,
    hideEditButtonWhenEmpty,
    disabled,
    disableLabelClick,
    hideEdit,
    tooltipString,
    policy,
    isCustomerSelect,
    readableClassName,
    hidePopover,
    ...other
  } = props;

  const [showInputComponent, setShowInputComponent] = useState(false);
  const [disableEdit, setDisableEdit] = useState(disabled);
  const [submitting, setSubmitting] = useState(false);

  const processedRawValue = isArray(value) ? [...value] : value;
  const initialValues = useMemo(() => {
    const sanitizedValue = (isArray(value) ? compact(value) : value) ?? undefined;
    const fieldName = isCustomField ? `custom_fields.${field.identifier}` : name;
    return set({}, fieldName, sanitizedValue);
  }, [processedRawValue]);

  const handleConfirm = (params) => {
    if (isEqual(initialValues, params)) {
      setSubmitting(false);
      setShowInputComponent(false);
    } else {
      setSubmitting(true);
      /*
       * Quando edicao in-place atualizar de forma consistente, deverá
       * mover setShowInputComponent(false) para dentro do onConfirm para reativar loading state
       */
      setShowInputComponent(false);
      onConfirm(params);
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setSubmitting(false);
    setShowInputComponent(false);
    onCancel(initialValues);
    hidePopover(true);
  };

  useEffect(() => {
    hidePopover(showInputComponent);
  }, [showInputComponent]);

  const EditButton = (editIconProps) => {
    useEffect(() => setDisableEdit(editIconProps.disabled), []);

    return (
      <Tooltip
        content={tooltipString}
        hide={disableEdit || !tooltipString}
        placement='top'
      >
        <div className='d-flex float-start'>
          <Icon
            className={classnames(
              disableEdit ? 'text-medium-gray' : 'text-primary',
              'cursor-pointer'
            )}
            name='edit-field'
          />
        </div>
      </Tooltip>
    );
  };

  const ReadableComponent = () => {
    if (hideEdit) {
      return children;
    }

    return (
      <div
        className={
          classnames(
            'readable-component',
            'd-flex',
            'cursor-pointer',
            readableClassName
          )
        }
        onClick={() => {
          if (!disableEdit && !disableLabelClick) {
            setShowInputComponent(true);
          }
        }}
      >
        {children}
        <div
          className='readable-component-button'
          onClick={() => setShowInputComponent(!disableEdit)}
        >
          <Authorization policy={policy}>
            <EditButton />
          </Authorization>
        </div>
      </div>
    );
  };

  const disableField = disableEdit || submitting;

  const WriteableComponent = () => (
    <div
      className={classnames(
        'top-0 start-0 position-relative height-2 z-index-1060',
        className
      )}
    >
      <Form
        name={`edit-in-place-form-${name}`}
        initialValues={initialValues}
        onReset={handleCancel}
        onSubmit={handleConfirm}
        disabled={disableField}
      >
        {({ handleChange, handleSubmit, handleReset }) => {
          const handleFormBlur = (event) => {
            const relatedElement = event.relatedTarget?.nodeName;
            const isBlurOverModal = relatedElement === 'DIV';
            if (isDatePicker) {
              return true;
            }

            if (isBlurOverModal) {
              if (isSelect) {
                handleReset();
              } else {
                handleSubmit();
              }
            }
          };

          const handleFormKeyPress = (event) => {
            if (event.key === 'Enter') {
              handleSubmit();
            } else if (event.key === 'Escape') {
              // O preventDefault é usado somente na tecla ESC para evitar close do modal
              event.preventDefault();
              handleReset();
            }
          };

          const handleFormChange = (newValue) => {
            if (!isSelect) {
              handleChange(newValue);
            }
          };

          return (
            <>
              <FormField
                autoFocus
                data-autofocus='true'
                as={Component}
                ref={ref}
                name={name}
                value={value}
                field={field}
                options={options}
                onChange={handleFormChange}
                onKeyDown={handleFormKeyPress}
                onBlur={handleFormBlur}
                disabled={disableField}
                {...isSelect ? { initialValue: value } : {}}
                {...other}
              />
              <div className={classnames(
                'd-flex justify-content-end',
                { 'my-n2': !isCustomerSelect },
                { 'my-2': isCustomerSelect }
              )}>
                <Button
                  variant='light'
                  className='me-1 opacity-100'
                  disabled={disableField}
                  onClick={() => {
                    handleReset();
                    hidePopover(true);
                  }}
                >
                  <Icon name='close' />
                </Button>
                <LoadingButton
                  variant='primary'
                  onClick={() => {
                    handleSubmit();
                    hidePopover(true);
                  }}
                  isLoading={submitting}
                  disabled={disableEdit}
                  loadingText=''
                  className='opacity-100'
                >
                  <Icon name='check' />
                </LoadingButton>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );

  return (
    showInputComponent
      ? <WriteableComponent />
      : <ReadableComponent />
  );
});

EditInPlace.propTypes = propTypes;
EditInPlace.defaultProps = defaultProps;
EditInPlace.displayName = 'EditInPlace';

export default EditInPlace;
