import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  legacyNewFunnelOptoutUrl,
  legacyNewFormsOptoutUrl,
  legacyNewListsOptoutUrl,
  legacyDealUrl,
  legacyOrganizationUrl
} from '@/routes';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import Icon from '@/components/Icon';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { TextArea } from '@/components/Inputs';
import Alert from '@/components/Alert';
import LoadingButton from '@/components/LoadingButton';
import storage from '@/lib/storage';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  entity: PropTypes.object,
  variant: PropTypes.string
};

const defaultProps = {
  show: false,
  onHide: () => {},
  onConfirm: () => {},
  entity: {},
  variant: ''
};

const schema = Yup.object().shape({
  feedback: Yup.string().required('Feedback obrigatório.')
});

const defaultValues = {
  feedback: ''
};

const VARIANTS = {
  newForms: {
    title: 'Funcionalidades que não existem nos formulários antigos:',
    items: ['Autopreenchimento por CNPJ', 'Campos customizados'],
    buttonUrl: (entity) => legacyNewFormsOptoutUrl(entity),
    formText: 'Compartilhe conosco o que sentiu falta nos novos formulários:',
    screen: 'Formulario',
    entityRelated: (type) => {
      if (type === 'deal') {
        return 'Negocio';
      }
      if (type === 'organization') {
        return 'Empresa';
      }
      return 'Pessoa';
    }
  },
  newLists: {
    title: 'Funcionalidades que não existem nas listagens antigas:',
    items: ['Visualização de todas as informações', 'Filtros avançados', 'Geração de leads'],
    buttonUrl: (entity) => legacyNewListsOptoutUrl(entity),
    formText: 'Compartilhe conosco o que sentiu falta na nova tela de listagem:',
    screen: 'Listagem',
    entityRelated: (type) => {
      if (type === 'deal') {
        return 'Negocio';
      }
      if (type === 'organization') {
        return 'Empresa';
      }
      return 'Pessoa';
    }
  },
  newFunnel: {
    title: 'Funcionalidades que não existem no funil antigo:',
    items: ['Múltiplos funis', 'Geração de leads'],
    buttonUrl: () => legacyNewFunnelOptoutUrl(),
    formText: 'Compartilhe conosco o que sentiu falta no novo funil de negócios:',
    screen: 'Funil',
    entityRelated: () => 'Negocio'
  },
  history: {
    title: 'Funcionalidades que não existem no histórico antigo:',
    items: [
      'Telefone virtual inteligente',
      'Envio de e-mails pelo Agendor',
      'Geração de propostas',
      'Edição mais rápida',
      'Modelos de anotação'
    ],
    buttonUrl: (id, entityType) => {
      if (entityType === 'deal') {
        return legacyDealUrl(id, true);
      }
      return legacyOrganizationUrl(id, true);
    },
    formText: 'Compartilhe conosco o que sentiu falta no histórico:',
    screen: 'Ficha',
    entityRelated: (type) => {
      if (type === 'deal') {
        return 'Negocio';
      }
      if (type === 'organization') {
        return 'Empresa';
      }
      return 'Pessoa';
    }
  }
};

const renderItems = (items) => (
  items.map((item, index) => (
    <div key={index} className='d-flex align-items-center mb-2'>
      <Icon name='alert' className='text-danger me-2' />
      <span className='fw-bold'>{item}</span>
    </div>
  ))
);

function BackToLegacyModal({ show, onHide, onConfirm, variant, entity, id, entityType, disabled }) {
  const { title, items, buttonUrl, formText, screen, entityRelated } = VARIANTS[variant] || {};
  const { user } = useAuth();
  const tracker = useTracking();

  const url = buttonUrl && (variant === 'newLists' || variant === 'newForms')
    ? buttonUrl(entity)
    : buttonUrl(id, entityType);

  const handleFeedbackSubmit = (form) => {
    if (onConfirm) {
      tracker.trackBackToLegacyConfirmed({
        user, feedback: form.feedback, screen, entity: entityRelated(entityType)
      });

      storage.set(`lastFeedbackDate_${screen}`, new Date().toISOString());

      onConfirm();
      window.location.href = url;
    }
  };

  return (
    <Modal bodyClassName='p-8' show={show} size='lg' backdrop='static' onHide={onHide}>
      <h3 className='mb-3'>Tem certeza que deseja voltar para a versão antiga 🥹?</h3>
      <Alert variant='light' className='text-dark-gray mb-2'>
        <span
          className='text-base'>
            A versão antiga será descontinuada no futuro e substituída pelo novo design.
        </span>
      </Alert>
      {title ? <div className='mb-3'>{title}</div> : null}
      {items ? <div>{renderItems(items)}</div> : null}
      <div className='mt-8'>
        <hr className='mx-2 text-light-gray opacity-75 mt-n4 mb-4' />
      </div>
      {formText ? <div className='mb-3 fw-bold'>{formText}</div> : null}
      <Form
        name='feedbackForm'
        validationSchema={schema}
        defaultValues={defaultValues}
        onSubmit={handleFeedbackSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <FormField as={TextArea} name='feedback' placeholder='Deixe seu feedback...' />
            <div className='d-flex mt-3 justify-content-end'>
              <Button className='me-3' variant='light' onClick={onHide}>Cancelar</Button>
              <LoadingButton
                variant='danger'
                onClick={handleSubmit}
                isLoading={isSubmitting}
                loadingText='Carregando...'
                disabled={disabled}
              >
                Voltar para a versão antiga
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

BackToLegacyModal.propTypes = propTypes;
BackToLegacyModal.defaultProps = defaultProps;

export default BackToLegacyModal;
