import React from 'react';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import notFoundImage from 'images/404.png';
import { isForbiddenError } from '@/errors';

function ModalError({ onHide, error, entityType }) {
  const notFoundMessage = () => {
    const entityName = {
      organization: 'a empresa',
      person: 'a pessoa',
      deal: 'o negócio'
    }[entityType];

    return `Parece que ${entityName} que você está procurando não <br /> existe.`;
  };

  const noPrivacyMessage = () => {
    const entityName = {
      organization: 'esta empresa',
      person: 'esta pessoa',
      deal: 'este negócio'
    }[entityType];

    return `Você não possui privacidade para acessar ${entityName}.<br />
    Entre em contato com seus administradores.`;
  };

  const errors = {
    notFound: {
      image: <img width={188} src={notFoundImage} className='img-fluid mb-3' />,
      title: 'Oops!',
      message: notFoundMessage(entityType)
    },
    noPrivacy: {
      image: <Icon name='lock' size='lg' className='mb-3 bg-light-gray rounded-2 p-2 m-8' />,
      title: 'Sem privacidade',
      message: noPrivacyMessage(entityType)
    }
  };

  function getErrorContent() {
    if (isForbiddenError(error)) {
      return errors.noPrivacy;
    }
    return errors.notFound;
  }

  const errorContent = getErrorContent();

  return (
    <div className='d-flex justify-content-center py-8'>
      <div className='text-center py-8'>
        {errorContent.image}
        <h2 className='text-darker-gray mb-3'>{errorContent.title}</h2>

        <div
          dangerouslySetInnerHTML={{ __html: errorContent.message }}
          className='text-dark-gray mb-8'
        />

        <div className='mb-8'>
          <Button onClick={onHide}>
            <Icon
              className='me-2'
              name='full-arrow-left'
              size='sm'
            />
          Voltar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ModalError;
