import { useMemo } from 'react';
import { addDays } from 'date-fns';
import { now } from '@/date';
import { useStoredState } from '@/hooks';

const WarningTime = 6;

export function useNextVisibleTime(keyPrefix, waitingDays = 1) {
  const key = `${keyPrefix}-next-visible-time`;
  const { value: nextVisibleTime, store: storeNextVisibleTime } = useStoredState(key, null);

  const nextVisibleDate = addDays(now(), waitingDays);

  const isWarningVisible = useMemo(() => {
    if (!nextVisibleTime) {
      return true;
    }
    return now() >= new Date(nextVisibleTime);
  }, [nextVisibleTime]);

  const handleWarningClose = () => {
    const visibleTime = new Date(nextVisibleDate);
    visibleTime.setHours(WarningTime, 0, 0, 0);
    storeNextVisibleTime(visibleTime.toISOString());
  };

  return {
    isWarningVisible,
    handleWarningClose
  };
}
