import React from 'react';
import { useLocation } from 'react-router-dom';

import { checkoutPath } from '@/routes';
import { parseDate, toTextualDateString } from '@/date';
import { useAuth } from '@/lib/auth';
import { useNextVisibleTime } from '@/hooks';

import Button from '@/components/Button';
import Icon from '@/components/Icon';

const accountBlockingWarningKey = 'headerAccountBlockingWarning';

export function useHeaderAccountBlockingWarning(currentPlanData) {
  const noHeaderWarningPrefix = '/settings/account/current_plan';

  const location = useLocation();
  const { user } = useAuth();
  const { fiveDaysUntilBlock } = user.account;
  const {
    isWarningVisible,
    handleWarningClose
  } = useNextVisibleTime(accountBlockingWarningKey);
  const isTrial = currentPlanData?.subscriptionType === 'trial';

  const isPathExcluded = location.pathname.startsWith(noHeaderWarningPrefix);

  if (fiveDaysUntilBlock && isWarningVisible && !isPathExcluded && !isTrial) {
    return { show: true, onClose: handleWarningClose };
  }

  return { show: false };
}

function HeaderAccountBlockingWarning({ onClose }) {
  const { user } = useAuth();

  const isAdmin = user.admin;
  const { blockAt, paymentTypeCode } = user.account;

  const isBankSlip = paymentTypeCode === 'bank_slip';
  const blockingDateFormatted = toTextualDateString(parseDate(blockAt));
  const buttonText = isBankSlip ? 'Gerar 2ª via boleto' : 'Alterar forma de pagamento';
  const buttonVariant = isBankSlip ? 'outline-dark-gray' : 'primary';
  const message = isAdmin
    ? 'Não identificamos o pagamento. Regularize a assinatura até o dia '
    : 'Entre em contato com o administrador da conta para regularizar a assinatura até o dia ';

  return (
    <div className='p-4 bg-danger bg-opacity-10'>
      <div className='p-0 d-flex align-items-center'>
        <div className='row w-100'>
          <div className='ms-6 col-auto d-flex align-items-center text-danger'>
            <Icon name='alert' size='sm' />
          </div>
          <div className='col d-flex flex-column justify-content-center'>
            <h4 className='mb-1 text-medium fw-bold'>Evite o bloqueio da sua conta</h4>
            <div className='text-dark-gray text-small'>
              {message}<b>{blockingDateFormatted}</b>.
            </div>
          </div>
          <div className='col-auto d-flex flex-column align-items-end'>
            {isAdmin && (
              <>
                {isBankSlip && (
                  <Button
                    href={checkoutPath()}
                    variant='outline-dark-gray'
                    className='w-100'
                    size='sm'
                  >
                    Pagar com Cartão de Crédito
                  </Button>
                )}
                <Button
                  href={checkoutPath()}
                  variant={buttonVariant}
                  className='mt-2 w-100'
                  size='sm'
                >
                  {buttonText}
                </Button>
              </>
            )}
          </div>
          <div className='col-auto d-flex flex-column justify-content-center cursor-pointer'>
            <Icon
              name='close'
              size='md'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderAccountBlockingWarning;
