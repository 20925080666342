import React, { useMemo, useState, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { legacyDealUrl, legacyOrganizationUrl, legacyPersonUrl } from '@/routes';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import { isPast } from 'date-fns';
import { now } from '@/date';
import { useCreateDealFreezings, useDeleteDealFreezing } from '@/api';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import ConfirmationModal from '@/components/ConfirmationModal';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import FormField from '@/components/FormField';
import DatePicker from '@/components/DatePicker';
import LoadingButton from '@/components/LoadingButton';
import Skeleton from '@/components/Skeleton';
import { TextArea, FreezingReasonSelect } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import Popover from '@/components/Popover';
import Form from '@/components/Form';
import BackToLegacyModal from '@/components/EntityModal/BackToLegacyModal';
import FeedbackToLegacyModal from '@/components/EntityModal/FeedbackToLegacyModal';
import { isFeedbackRecent } from '@/utils';

const defaultValues = { deal_freezing_reasons: {} };

function FreezingForm({ onHide, handleOnSubmit, dealId }) {
  const initialValues = useMemo(() => ({
    deal_id: dealId, unfreeze_at: now()
  }), [dealId]);

  return (
    <Form
      name='deal-freezing-reasons-form'
      onSubmit={(values) => handleOnSubmit(values, onHide)}
      initialValues={initialValues}
      defaultValues={defaultValues}
      className='d-flex'
    >
      {({ handleBlur, handleSubmit, isSubmitting, values }) => {
        const getTooltipMessage = () => {
          const isFreezingReasonFilled = Boolean(values.deal_freezing_reason_id);

          if (!isFreezingReasonFilled || !(values.unfreeze_at)) {
            return 'Para salvar, selecione a data prevista para o descongelamento e o motivo.';
          }
          if (!isFreezingReasonFilled) {
            return 'Para salvar, você precisa selecionar um motivo para o congelamento.';
          }
          if (isPast(values.unfreeze_at)) {
            return 'Para salvar, você precisa selecionar uma data futura para o descongelamento.';
          }
        };

        const tooltipMessage = getTooltipMessage();
        const hasErros = Boolean(tooltipMessage);

        return (
          <>
            <div className='d-flex flex-column bg-white px-4 py-6'>
              <h4>Congelar até quando?</h4>
              <FormField
                name='unfreeze_at'
                mode='single'
                as={DatePicker}
                selected={values.unfreeze_at}
              />
            </div>

            <div className='d-flex flex-column min-width-5 bg-light px-4 py-6'>
              <h4 className='mb-6'>Por que foi congelado?</h4>
              <FormField
                as={FreezingReasonSelect}
                name='deal_freezing_reason_id'
                controlClassName='bg-white'
                onBlur={handleBlur}
              />
              <FormField
                as={TextArea}
                name='description'
                variant='white'
                placeholder='Comentário (opcional)'
                maxLength={250}
                onBlur={handleBlur}
              />
              <Tooltip
                placement='bottom'
                content={tooltipMessage}
                trigger={['hover']}
                hide={!hasErros}
              >
                <div>
                  <LoadingButton
                    disabled={hasErros}
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    className={classnames('w-100', {
                      'pe-none': hasErros
                    })}
                  >
                      Salvar
                  </LoadingButton>
                </div>
              </Tooltip>
            </div>
          </>
        );
      }}
    </Form>
  );
}

Menu.defaultProps = {
  entity: {},
  loading: true,
  onDelete: () => {},
  editPath: '',
  entityType: 'deal'
};

Menu.propTypes = {
  entity: PropTypes.object,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  editPath: PropTypes.string,
  entityType: PropTypes.string
};

function Menu({ entity, onDelete, loading, editPath, entityType }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const [showDelete, setShowDelete] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [showLegacyModal, setShowLegacyModal] = useState(false);

  const onConfirmDelete = () => {
    setSubmiting(true);
    onDelete(entity?.id, (err) => {
      setSubmiting(false);
      if (!err) {
        setShowDelete(false);
      }
    });
  };

  const legacyUrl = {
    organization: legacyOrganizationUrl(entity?.id, true),
    person: legacyPersonUrl(entity?.id, true),
    deal: legacyDealUrl(entity?.id, true)
  };

  const handleUseLegacyClick = () => {
    window.location.href = legacyUrl[entityType];
  };

  const handleOpenModal = () => {
    const screen = entityType === 'deal' ? 'Ficha' : 'Feedback';
    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy(
      { user, page: screen, entity: entityType, feedback: !feedbackGivenRecently }
    );

    if (feedbackGivenRecently) {
      const redirectUrl = legacyUrl[entityType];
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  const createDealFreezings = useCreateDealFreezings();

  const handleSubmit = (values, onHide) => {
    createDealFreezings.mutate(values, {
      onSuccess: () => {
        onHide();
      }
    });
  };

  const popoverRef = useRef(null);
  const container = useRef(null);
  const isFreeze = entity?.freezing?.active;
  const unfreezeDeal = useDeleteDealFreezing();

  if (loading) {
    return <Skeleton width={110} height={30} className='ms-3' />;
  }

  const wordGenre = entityType === 'deal' ? 'este' : 'esta';
  const entityName = {
    organization: 'empresa',
    person: 'pessoa',
    deal: 'negócio'
  }[entityType];

  const labels = {
    edit: `Editar ${entityName}`,
    delete: `Excluir ${entityName}`,
    confirm: `Deseja excluir ${wordGenre} ${entityName}`
  };

  return (
    <>
      <Dropdown className='justify-content-end'>
        <Popover
          rootClose
          rootCloseEvent='mousedown'
          placement='left-start'
          contentClassName='p-0'
          ref={popoverRef}
          container={container.current}
          trigger={['click']}
          content={({ onHide }) => (
            <FreezingForm
              dealId={entity?.id}
              handleOnSubmit={handleSubmit}
              onHide={onHide}
            />
          )}
        >
          <div type='hidden' ref={container} />
        </Popover>
        <Dropdown.Toggle size='sm' as={Button} variant='light'>
          <Icon name='arrow-down' className='me-1' />
          Mais opções
        </Dropdown.Toggle>
        <Dropdown.Menu renderOnMount className='min-width-5 z-index-1090'>
          {
            entityType === 'deal'
              ? (
                <>
                  <Dropdown.Item
                    as='button'
                    onClick={() => {
                      if (isFreeze) {
                        unfreezeDeal.mutate({ deal_id: entity?.id });
                      } else {
                        popoverRef.current?.setShow(true);
                      }
                    }}
                  >
                    { isFreeze
                      ? <>
                        <Icon name='unfrozen' className='me-2 text-primary' />
                        <span className='fw-bold'>
                          Descongelar negócio
                        </span>
                      </>
                      : <>
                        <Icon name='frozen' className='me-2 text-primary' />
                        <span className='fw-bold'>
                          Congelar negócio
                        </span>
                      </>
                    }
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>
              )
              : null
          }
          <Dropdown.Item href={editPath}>
            <Icon name='edit-field' className='me-2 text-primary' />
            <span className='fw-bold'>{labels.edit}</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href='#' disabled>
            <Icon name='download' className='me-2' />
            <span className='fw-bold'>
              Exportar histórico
            </span>
            <Badge className='float-end mt-1'>Em breve</Badge>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as='button'
            onClick={handleOpenModal}
          >
            <Icon name='close-beta' className='me-2 text-primary' />
            <span className='fw-bold'>Usar versão antiga</span>
          </Dropdown.Item>
          <Dropdown.Item
            as='button'
            onClick={() => setShowDelete(true)}
            className='text-danger'
          >
            <Icon name='delete' className='me-2' />
            <span className='fw-bold'>
              {labels.delete}
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {
        entityType === 'deal'
          ? (
            <BackToLegacyModal
              show={showLegacyModal}
              onHide={() => setShowLegacyModal(false)}
              onConfirm={handleUseLegacyClick}
              variant='history'
              id={entity?.id}
              entityType={entityType}
            />
          )
          : (
            <FeedbackToLegacyModal
              show={showLegacyModal}
              onHide={() => setShowLegacyModal(false)}
              onConfirm={handleUseLegacyClick}
              id={entity?.id}
              entityType={entityType}
            />
          )
      }

      <ConfirmationModal
        confirmationLoadingText='Excluindo...'
        confirmationText='Excluir'
        show={showDelete}
        onConfirm={onConfirmDelete}
        onHide={() => setShowDelete(false)}
        loading={submiting}
        disabled={submiting}
      >
        <ConfirmationModal.Title>
          {labels.confirm}
        </ConfirmationModal.Title>

        <ConfirmationModal.Info className='mb-6'>
          Todo o histórico de relacionamento será perdido.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </>
  );
}

export default Menu;
