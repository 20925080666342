import React from 'react';
import { Navigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import { useCurrentPlan } from '@/api';
import { useDocumentTitle } from '@/hooks';
import { plansPath, settingsManageCurrentPlanPath } from '@/routes';

import Button from '@/components/Button';
import CurrentAccountBanner from '@/components/CurrentAccountBanner';
import CurrentPlanCard from '@/components/Billing/CurrentPlanCard';
import Icon from '@/components/Icon';
import Link from '@/components/Link';
import Main from '@/components/Main';
import PaymentDetailsCard from '@/components/PaymentDetailsCard';
import PaymentWarningCard from '@/components/PaymentWarningCard';
import PlanAddonsCard from '@/components/PlanAddonsCard';

const PAGE_TITLE = 'Plano';
const PAGE_SUBTITLE = `Gerencie seu plano no Agendor,
método de pagamento e outras informações importantes.`;

function CurrentPlan() {
  useDocumentTitle(PAGE_TITLE);

  const { data: { data } = {}, isSuccess } = useCurrentPlan();

  const isFreePlan = data?.subscriptionType === 'free';
  const isTrial = data?.subscriptionType === 'trial';
  const managePlanUrl = isFreePlan ? plansPath() : settingsManageCurrentPlanPath();

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  if (isTrial) {
    return <Navigate to={plansPath()} />;
  }

  return (
    <Main fluid className='py-8 ps-4 pe-8'>
      <Container>
        {data?.paymentWarning &&
        <PaymentWarningCard
          type={data.paymentWarning}
          blockAccessDate={data.blockAccessDate}
          expirationDate={data.expirationDate}
        />
        }
        <h2 className='mb-2'>{PAGE_TITLE}</h2>

        <div className='d-flex align-items-center'>
          <p className='text-dark-gray'>{PAGE_SUBTITLE}</p>

          <Button
            as={Link}
            href={managePlanUrl}
            size='md'
            className='ms-auto mb-3 align-self-start fw-bold text-nowrap'
          >
            <Icon name='config' className='me-1' />
            Gerenciar plano
          </Button>
        </div>

        <CurrentAccountBanner
          subscriptionType={data.subscriptionType}
          expirationDate={data.expirationDate}
          isSuccess={isSuccess}
          canceled={data.canceled}
          name={data.name}
          trialAvailable={data.trialAvailable}
        />

        <Row className='mb-2'>
          <Col sm={7} className='pe-1'>
            <CurrentPlanCard data={data} />
          </Col>
          <Col sm={5} className='ps-1'>
            <PaymentDetailsCard
              expired={data.expired}
              blocked={data.blocked}
              canceled={data.canceled}
              subscriptionType={data.subscriptionType}
              periodicity={data.periodicity}
              revenueDate={data.paymentInfo?.revenueDate}
              method={data.paymentInfo?.method}
              lastFourDigits={data.paymentInfo?.lastFourDigits}
            />
          </Col>
        </Row>
        <PlanAddonsCard />
      </Container>
    </Main>
  );
}

export default CurrentPlan;
