import React from 'react';
import PropTypes from 'prop-types';
import { applyMask } from '@/masks';
import Button from '@/components/Button';
import Card from '@/components/Card';
import CardBrand from '@/components/Billing/CardBrand';
import Icon from '@/components/Icon';

const propTypes = {
  legalName: PropTypes.string,
  cpfCnpj: PropTypes.string,
  address: PropTypes.object,
  paymentInfo: PropTypes.object,
  showEditButton: PropTypes.bool,
  onClickEditButton: PropTypes.func
};

const defaultProps = {
  isPlanPayment: true,
  showEditButton: false,
  onClickEditButton: () => {}
};

function PaymentConfirmation(props) {
  const {
    legalName,
    cpfCnpj,
    address,
    paymentInfo,
    showEditButton,
    onClickEditButton
  } = props;

  const formattedPostalCode = applyMask(address.postalCode, 'postalCode');
  const formattedCpfCnpj = applyMask(cpfCnpj, 'cpf_cnpj');

  return (
    <>
      <Card body className='p-2 text-dark-gray text-small border-light'>
        <div>
          <div className='d-flex justify-content-between mb-2'>
            <h5 className='mb-3'>Dados do faturamento</h5>
            {
              showEditButton &&
              <Button
                variant='outline-dark-gray'
                onClick={onClickEditButton}
                size='sm'
                className='p-1'
              >
                <Icon name='edit-field' size='sm'/>
              </Button>
            }
          </div>
          <h5 className='mb-1'>{legalName}</h5>
          <div className='mb-1'>
            {formattedCpfCnpj}
          </div>
          <div className='mb-1'>
            {address.streetName}, {address.streetNumber} - {address.district}
          </div>
          <div className='mb-1'>
            {formattedPostalCode}, {address.city.name} - {address.state}
          </div>
        </div>
      </Card>

      <Card body className='p-2 text-dark-gray text-small mt-3 border-light'>
        <div>
          <h5>Forma de pagamento</h5>

          <div className='d-flex align-items-center justify-content-between my-5'>
            <div className='d-flex align-items-center'>
              {getPaymentContent(paymentInfo)}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

function getPaymentContent(paymentInfo) {
  switch (paymentInfo.type) {
    case 'credit_card':
      return (
        <>
          <CardBrand
            cardType={paymentInfo.credit_card.brand || paymentInfo.paymentMethod.card.brand}
            className='me-2 width-2 height-2'
          />
          <h5 className='text-medium-gray m-0'>
            ••••  ••••  •••• {paymentInfo.credit_card.end_digits ||
            paymentInfo.paymentMethod.card.last4}
          </h5>
        </>
      );
    case 'bank_slip':
      return (
        <>
          <div className='p-1 rounded bg-lighter-gray me-2'>
            <Icon name='bar-code' />
          </div>
          <h5>Boleto</h5>
        </>
      );
    case 'pix':
      return (
        <>
          <div className='p-1 rounded bg-lighter-gray me-2'>
            <Icon name='pix' />
          </div>
          <h5>Pix</h5>
        </>
      );
    default:
      return null;
  }
}

PaymentConfirmation.propTypes = propTypes;
PaymentConfirmation.defaultProps = defaultProps;


export default PaymentConfirmation;
