import React, { useEffect, useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAlert } from 'react-alert';
import { useParams, useNavigate } from 'react-router-dom';
import { useTracking } from '@/lib/tracking';
import Main from '@/components/Main';
import FullPersonForm from '@/components/FullPersonForm';
import APIErrorMessage from '@/components/APIErrorMessage';
import NotFound from '@/pages/not-found';
import { redirect, goBack, setLegacyAlertMessage } from '@/browser';
import { getValidationErrors, isFeedbackRecent } from '@/utils';
import { useDocumentTitle } from '@/hooks';
import { legacyNewFormsOptoutUrl, legacyPersonUrl } from '@/routes';
import { isNotFoundError } from '@/errors';
import { parseDate, toShortDateString } from '@/date';
import { usePerson, useUpdatePerson, useDeletePerson, usePeopleCustomFields } from '@/api';
import { useAuth } from '@/lib/auth';
import OptoutButton from '@/components/OptoutButton';
import ConfirmationModal from '@/components/ConfirmationModal';
import { getInitialValues as getPrivacyInitialValues } from '@/components/Inputs/PrivacyInput';
import { getInitialValues as getCustomFieldsInitialValues } from '@/components/CustomFields';
import BackToLegacyModal from '@/components/EntityModal/BackToLegacyModal';

const PAGE_TITLE = 'Alterar pessoa';

function PeopleEdit() {
  useDocumentTitle(PAGE_TITLE);

  const { personId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const alert = useAlert();
  const updatePersonMutation = useUpdatePerson({
    /**
     * Quando essa mutation é bem-sucedida, por padrão as queries relacionadas
     * são invalidadas e, consequentemente, seus dados são buscados novamente.
     * Porém, aqui isso não é necessário, porque o usuário vai ser redirecionado
     * para o sistema legado logo em seguida.
     * Por isso, aqui é sobrescrito o callback padrão de `onSuccess`.
     */
    config: { onSuccess: () => {} }
  });
  const { data: person, isSuccess, error } = usePerson(
    { personId, params: { avatar_size: 'large' } }
  );
  const { data: customFields, isSuccess: loadedCustomFields } = usePeopleCustomFields();
  const tracker = useTracking();
  const [showLegacyModal, setShowLegacyModal] = useState(false);

  const handleOpenModal = () => {
    const screen = 'Formulario';
    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy({
      user,
      page: 'Formulario',
      entity: 'Pessoa',
      feedback: !feedbackGivenRecently
    });
    if (feedbackGivenRecently) {
      const redirectUrl = legacyNewFormsOptoutUrl({ type: 'person', id: personId });
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  useEffect(() => {
    tracker.trackNewEditPersonFormViewed({ user });
  }, [user]);

  const handleSubmit = (formData, { setFieldError, setSubmitting }) => {
    updatePersonMutation.mutate({ personId, params: formData }, {
      onSuccess: ({ data: { id } }) => {
        setLegacyAlertMessage('Pessoa atualizada com sucesso!', 'success');
        redirect(legacyPersonUrl(id));
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'person');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger' }
        );
        setSubmitting(false);
      }
    });
  };

  const initialValues = useMemo(
    () => (person ? prepareInitialData(person.data) : {}),
    [person]
  );

  const handleCancel = () => goBack();

  const onCreateOrganization = () => {
    tracker.trackOrganizationCreated({ quickAdded: true, source: 'newForm', user });
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deletePersonMutation = useDeletePerson(personId);

  const onClickDelete = () => setShowDeleteConfirm(true);
  const onCancelDelete = () => setShowDeleteConfirm(false);

  const onConfirmDelete = () => {
    setIsDeleting(true);

    deletePersonMutation.mutate({ personId }, {
      onError: (err) => {
        setIsDeleting(false);

        alert.show(
          <APIErrorMessage err={err} resource='person' action='delete' />,
          { variant: 'danger' }
        );
      },
      onSuccess: () => {
        alert.show(
          'Pessoa removida com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        navigate('/people');
      }
    });
  };

  if (error && isNotFoundError(error)) {
    return <NotFound />;
  }

  if (!isSuccess || !loadedCustomFields) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid flex>
      <div className='py-6 border-bottom'>
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex align-items-center justify-content-between'
            >
              <h1>{PAGE_TITLE}</h1>

              <OptoutButton
                from='newForms'
                entity={{ type: 'person', id: personId }}
                onClick={handleOpenModal}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <BackToLegacyModal
        show={showLegacyModal}
        onHide={() => setShowLegacyModal(false)}
        variant='newForms'
        entity={{ type: 'person', id: personId }}
        entityType={'person'}
      />

      <FullPersonForm
        isEdit
        initialValues={initialValues}
        customFields={customFields?.data ?? []}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onCreateOrganization={onCreateOrganization}
        onDelete={onClickDelete}
        personId={personId}
      />

      <ConfirmationModal
        confirmationText='Excluir'
        show={showDeleteConfirm}
        onConfirm={onConfirmDelete}
        onHide={onCancelDelete}
        disabled={isDeleting}
      >
        <ConfirmationModal.Title>
          Deseja excluir esta pessoa?
        </ConfirmationModal.Title>

        <ConfirmationModal.Info>
          Todo o histórico de relacionamento será perdido.
        </ConfirmationModal.Info>
      </ConfirmationModal>
    </Main>
  );
}

function formatBirthday(birthday) {
  return birthday ? toShortDateString(parseDate(birthday)) : '';
}

function listProductsId(products = []) {
  return products.map((product) => product.id);
}

function formatContact(contact) {
  return {
    email: contact.email ?? '',
    whatsapp: contact.whatsapp ?? '',
    work_phone: contact.workPhone ?? '',
    mobile_phone: contact.mobilePhone ?? '',
    fax_phone: contact.faxPhone ?? '',
    phone_extension: contact.phoneExtension ?? '',
    facebook: contact.facebook ?? '',
    twitter: contact.twitter ?? '',
    linkedin: contact.linkedin ?? '',
    instagram: contact.instagram ?? '',
    skype: contact.skype ?? ''
  };
}

function formatAddress(address) {
  return {
    postal_code: address.postalCode ?? '',
    country: address.country ?? '',
    state: address.state ?? '',
    city_id: address.city?.id,
    district: address.district ?? '',
    street_name: address.streetName ?? '',
    street_number: address.streetNumber?.toString(),
    additional_info: address.additionalInfo ?? ''
  };
}

function prepareInitialData(data) {
  const {
    contact, address, organization,
    birthday, birthYear, category,
    leadOrigin, products, ownerUser
  } = data;

  return {
    name: data.name,
    cpf: data.cpf ?? '',
    avatar: data.avatar,
    organization,
    organization_id: organization?.id,
    job_title: data.jobTitle ?? '',
    birthday: formatBirthday(birthday),
    birth_year: birthYear?.toString(),
    owner_user_id: ownerUser?.id ?? '',
    category_id: category?.id,
    lead_origin_id: leadOrigin?.id,
    description: data.description ?? '',
    products_id: listProductsId(products),
    contact: formatContact(contact),
    address: formatAddress(address),
    custom_fields: getCustomFieldsInitialValues(data.customFields),
    ...getPrivacyInitialValues(data)
  };
}

export default PeopleEdit;
