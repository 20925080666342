import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { legacyRegisterUserUrl, settingsManageUpdatePlanPath } from '@/routes';
import { parseDate, toDateString } from '@/date';
import { pluralize } from '@/utils';
import { toCurrencyString } from '@/number';

import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Badge from '@/components/Badge';
import ExternalLink from '@/components/ExternalLink';
import Popover from '@/components/Popover';
import CurrentPlanPaymentSummary from '@/components/Billing/CurrentPlanPaymentSummary';

const propTypes = {
  data: PropTypes.object
};

function CurrentPlanCard({ data }) {
  const [showEditPlanButton, setShowEditPlanButton] = useState(false);

  const currentActiveUsersText = getCurrentActiveUsersText(data);
  const [isUsersLeft, leftUsersText] = getLeftUsersInfo(data);

  const isPaid = data.subscriptionType === 'paid';

  return (
    <div
      className='d-flex flex-column h-100 p-4 border rounded border-light text-small'
      onMouseEnter={() => setShowEditPlanButton(true)}
      onMouseLeave={() => setShowEditPlanButton(false)}
    >
      {
        isPaid
          ? <PaidPlanInfo
            planData={data}
            showEditPlanButton={showEditPlanButton}
          />
          : <FreePlanInfo planData={data}/>
      }

      <hr />

      <div className='mt-2 d-flex align-items-start justify-content-between'>
        <div>
          <div className='mt-1 text-dark-gray'>
            {currentActiveUsersText}
          </div>
          {
            isUsersLeft &&
            <Badge variant='light-green' className='mt-1 fw-normal text-small'>
              {leftUsersText}
            </Badge>
          }
        </div>

        <Button
          as={ExternalLink}
          variant='light-blue'
          className='text-primary'
          href={legacyRegisterUserUrl()}
        >
          <Icon className='me-1' name='add'/> Convidar usuários
        </Button>
      </div>
    </div>
  );
}

function PaidPlanInfo({ planData, showEditPlanButton }) {
  const navigate = useNavigate();
  const finalMonthlyValue = toCurrencyString(planData.monthlyInfo.value);

  const isBlockedOrCanceled = planData?.blocked || planData?.canceled;
  const expiredOrCanceled = planData?.expiredOrExpiringToday || isBlockedOrCanceled;

  return (
    <div className='d-flex justify-content-between mt-2 mb-2'>
      <div className='flex-fill'>
        <h4>
          Agendor CRM {planData.name} {planData.maxUsersCount}

          {!expiredOrCanceled && showEditPlanButton &&
            <Button
              variant='link'
              className='text-small py-0 my-0 border-0'
              onClick={() => navigate(settingsManageUpdatePlanPath())}
            >
              Editar
            </Button>
          }

          {planData.canceled &&
            <Badge variant='light-red' className='text-small ms-2'>
              Cancelado
            </Badge>
          }
        </h4>
      </div>

      <div className='d-flex align-items-center'>
        <h4 className='d-inline me-1'>
          {planData.canceled
            ? `Válido até ${toDateString(parseDate(planData.expirationDate))}`
            : `${finalMonthlyValue}/mês`
          }
        </h4>

        {!planData.canceled && (
          <Popover
            content={() => (
              <CurrentPlanPaymentSummary
                monthlyInfo={planData.monthlyInfo}
                expirationDate={planData.expirationDate}
                finalValue={planData.finalValue}
                periodicity={planData.periodicity}
                maxUsersCount={planData.maxUsersCount}
              />
            )}
            contentClassName='p-3 bg-light'
            trigger={['click']}
            placement='bottom-end'
            rootClose
            rootCloseEvent='mousedown'
            flip
          >
            <div className='d-flex align-items-center'>
              <Icon
                className='text-dark-gray cursor-pointer link-primary'
                name='info'
                size='sm'
              />
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
}

function FreePlanInfo({ planData }) {
  return (
    <div className='mt-2 mb-2 d-flex justify-content-between'>
      <div>
        <h2>Gratuito</h2>

        <div className='mt-1 text-dark-gray'>
          <span>{planData.currentActiveUsersCount} </span>
          <span>{pluralize('usuário', planData.currentActiveUsersCount)} </span>
          <span>x R$ 0,00</span>
        </div>
      </div>

      <div className='d-flex align-items-center'>
        <h2 className='d-inline me-1'>R$ 0</h2>
      </div>
    </div>
  );
}

function getCurrentActiveUsersText(currentPlan) {
  return `
    ${currentPlan.currentActiveUsersCount}
    ${pluralize('usuário', currentPlan.currentActiveUsersCount)}
    ${pluralize('ativo', currentPlan.currentActiveUsersCount)}
  `;
}

function getLeftUsersInfo(currentPlan) {
  let isUsersLeft = false;
  let leftUsersText = '';

  if (currentPlan.maxUsersCount) {
    const leftUsersCount = currentPlan.maxUsersCount - currentPlan.currentActiveUsersCount;

    isUsersLeft = leftUsersCount !== 0;

    const message = leftUsersCount === 1
      ? 'convite de usuário disponível'
      : 'convites de usuário disponíveis';

    leftUsersText = `${leftUsersCount} ${message}`;
  }


  return [isUsersLeft, leftUsersText];
}

CurrentPlanCard.propTypes = propTypes;

export default CurrentPlanCard;
