import React, { useEffect } from 'react';
import { IntercomProvider as Base } from 'react-use-intercom';
import { useIntercomUserData } from '@/lib/intercom/hooks/use-intercom-user-data';
import { IntercomLocationTracker } from '@/lib/intercom/IntercomLocationTracker';
import { INTERCOM_APP_ID } from '@/config';
import { useAgendorChatContext } from '@/contexts/agendor-chat';

export function IntercomProvider({ children }) {
  const userData = useIntercomUserData();
  const { isOpen } = useAgendorChatContext();

  const setIntercomButtonVisibility = () => {
    const displayValue = !isOpen ? '' : 'none';

    document.querySelectorAll(
      '.intercom-lightweight-app-launcher, [class*="intercom-with-namespace"]:not(iframe)'
    ).forEach((launcher) => {
      launcher.style.display = displayValue;
    });
  };

  useEffect(() => {
    setIntercomButtonVisibility();
  }, [isOpen]);

  return (
    <Base appId={INTERCOM_APP_ID} autoBoot autoBootProps={userData}>
      <IntercomLocationTracker>
        {children}
      </IntercomLocationTracker>
    </Base>
  );
}
