import React, { useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useAuth } from '@/lib/auth';
import { useCheckoutContext } from '@/contexts';
import { useTracking } from '@/lib/tracking';

import BillingFooter from '@/components/Billing/BillingFooter';
import CardCheckoutDetails from '@/components/Billing/CardCheckoutDetails/CardCheckoutDetails';

const propTypes = {
  show: PropTypes.bool,
  radioOptions: PropTypes.array,
  isStripeComponent: PropTypes.bool
};

const defaultProps = {
  show: false,
  radioOptions: null
};

function Payment({ show, radioOptions, isStripeComponent }) {
  const {
    nextStep,
    paymentData,
    currentPlanData,
    setPaymentData,
    accountData,
    setEncryptedData,
    onCreatePayment,
    isLoadingCreatePayment,
    transactionPollingEnabled,
    period: selectedPeriod
  } = useCheckoutContext();

  const { user } = useAuth();
  const tracker = useTracking();
  const alert = useAlert();

  useEffect(() => {
    if (show && isStripeComponent) {
      tracker.trackPaymentStarted({ user });
    }
  }, [show, isStripeComponent]);

  const footerButtonText = useMemo(() => {
    if (paymentData.type === 'bank_slip') {
      return { default: 'Gerar boleto', loading: 'Gerando...' };
    }

    return { default: 'Avançar', loading: 'Avançando...' };
  }, [paymentData.type]);

  const initialValues = useMemo(() => ({
    type: paymentData.type
  }), [paymentData.type]);

  const isRadioDisabled = useCallback((label) => {
    if (currentPlanData) {
      const { periodicity, paymentInfo, allowMonthlyBankSlipPix } = currentPlanData;
      const alreadyUseBankSlipPix =
        periodicity === 'monthly' && ['bank_slip', 'pix'].includes(paymentInfo?.method);

      if (alreadyUseBankSlipPix || allowMonthlyBankSlipPix) {
        return false;
      }
    }

    return selectedPeriod === 'monthly' && label !== 'credit_card';
  }, [selectedPeriod, currentPlanData]);

  const handleChange = (value) => {
    setPaymentData((prev) => ({ ...prev, type: value }));
  };

  const isAdvanceStepDisabled = paymentData.type === 'credit_card' &&
    ((!paymentData?.event?.complete) && (
      !paymentData.credit_card?.is_valid ||
      !paymentData.credit_card?.brand));

  const handlePaymentError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos validar o seu cartão</h6>
        <div>Por favor, tente novamente ou adicione um novo.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const onClickNext = async () => {
    if (!isStripeComponent) {
      nextStep();

      return;
    }

    if (['pix', 'bank_slip'].includes(paymentData.type)) {
      return onCreatePayment();
    }

    if (show && paymentData.stripe) {
      const { error, paymentMethod } = await paymentData.stripe.createPaymentMethod({
        elements: paymentData.elements,
        params: {
          billing_details: {
            address: {
              country: 'BR'
            }
          }
        }
      });

      if (error) {
        console.error(error);

        setPaymentData((prev) => ({ ...prev, error }));

        return handlePaymentError();
      }

      setPaymentData((prev) => ({ ...prev, paymentMethod }));
    }

    nextStep();
  };

  return (
    <>
      <CardCheckoutDetails
        className={classnames({ 'd-none': !show })}
        accountData={accountData}
        initialValues={initialValues}
        setPaymentData={setPaymentData}
        setEncryptedData={setEncryptedData}
        handleChange={handleChange}
        isRadioDisabled={isRadioDisabled}
        disabledToolTipContent='Boleto e Pix não estão disponíveis para pagamento mensal.'
        radioOptions={radioOptions}
        isStripeComponent={isStripeComponent}
      />

      {
        show &&
        <BillingFooter
          buttonNextText={footerButtonText.default}
          loadingTextButton={footerButtonText.loading}
          onClickNext={onClickNext}
          isLoading={isLoadingCreatePayment || transactionPollingEnabled}
          disabled={isAdvanceStepDisabled}
        />
      }
    </>
  );
}

Payment.propTypes = propTypes;
Payment.defaultProps = defaultProps;

export default Payment;
