import React, { useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import classnames from 'classnames';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import PropTypes from 'prop-types';
import {
  legacyHomeUrl,
  legacyTasksUrl,
  legacyOrganizationsUrl,
  legacyPeopleUrl,
  legacyDealsUrl,
  legacyReportsUrl,
  DEALS_VIEW_PAGES,
  legacyNewFunnelOptoutUrl,
  legacyNewListsOptoutUrl
} from '@/routes';
import { getDealsViewCookie } from '@/hooks';
import HeaderItem from '@/components/HeaderItem';
import HeaderItemsDropdown from '@/components/HeaderItemsDropdown';
import HeaderMenu from '@/components/HeaderMenu';
import HeaderUser from '@/components/HeaderUser';
import HeaderSearch from '@/components/HeaderSearch';
import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';
import OptoutButton from '@/components/OptoutButton';
import BackToLegacyModal from '@/components/EntityModal/BackToLegacyModal';
import { isFeedbackRecent } from '@/utils';

const propTypes = {
  entity: PropTypes.string
};

const defaultProps = {};

const OPTOUT_CONFIGS = {
  organizations: {
    entity: { type: 'organization' }, from: 'newLists', exact: true
  },
  people: {
    entity: { type: 'person' }, from: 'newLists', exact: true
  },
  deals: {
    entity: { type: 'deal' }, from: 'newLists', exact: true
  },
  funnels: {
    entity: null, from: 'newFunnel', exact: false
  }
};

function Header() {
  const { user } = useAuth();
  const tracker = useTracking();
  const [isSearchActive, setIsSearchActive] = useState(false);

  const searchRef = useRef(null);

  const onClick = () => {
    setIsSearchActive(true);
  };

  const match = useMatch('/:resource/*');

  const matchResource = match?.params.resource;
  const matchRest = match?.params['*'];

  const { showOptout, optoutFrom, optoutEntity } = getOptoutConfig(matchResource, matchRest);


  const activeKey = matchResource === 'funnels' ? 'deals' : matchResource;

  const screen = matchResource === 'funnels' ? 'Funil' : 'Listagem';

  const dealsView = getDealsView(matchResource, matchRest);

  const [showLegacyModal, setShowLegacyModal] = useState(false);

  const handleOpenModal = () => {
    const page = optoutFrom === 'newFunnel' ? 'Funil' : 'Listagem';
    const entityType = optoutEntity ? optoutEntity.type : 'Negocio';
    const legacyUrl = {
      newFunnel: legacyNewFunnelOptoutUrl(),
      newLists: legacyNewListsOptoutUrl(optoutEntity)
    };

    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy({ user, entity: entityType, page, feedback: !feedbackGivenRecently });

    if (feedbackGivenRecently) {
      const redirectUrl = legacyUrl[optoutFrom];
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  const navbarClasses = classnames(
    'p-0',
    'align-items-stretch',
    'flex-shrink-0',
    'overflow-x-clip'
  );

  return (
    <>
      <Navbar
        onMouseLeave={() => {
          if (!searchRef.current?.hasSearchText()) {
            setIsSearchActive(false);
          }
        }}
        variant='dark'
        bg='darker-gray'
        className={navbarClasses}
      >
        <Navbar.Brand
          className='p-3 me-0'
          as={ExternalLink}
          href={legacyHomeUrl()}
        >
          <Icon
            className='text-white'
            name='agendor-logo-small'
            size='lg'
          />
        </Navbar.Brand>

        <Nav
          className={classnames(
            'align-items-stretch',
            'header-nav',
            'me-auto',
            isSearchActive ? 'd-none' : 'd-flex'
          )}
          activeKey={activeKey}
        >
          <HeaderItem
            href={legacyHomeUrl()}
            icon='home'
            text='Início'
            as={ExternalLink}
            eventKey='home'
          />

          <HeaderItem
            href={legacyTasksUrl()}
            icon='task'
            text='Tarefas'
            as={ExternalLink}
            eventKey='tasks'
          />

          <HeaderItem
            className='d-none d-sm-flex align-items-center'
            href={legacyOrganizationsUrl()}
            icon='organization'
            text='Empresas'
            as={ExternalLink}
            eventKey='organizations'
          />

          <HeaderItem
            className='d-none d-sm-flex align-items-center'
            href={legacyPeopleUrl()}
            icon='person'
            text='Pessoas'
            as={ExternalLink}
            eventKey='people'
          />

          <HeaderItem
            className='d-none d-sm-flex align-items-center'
            href={legacyDealsUrl(dealsView)}
            icon='deal'
            text='Negócios'
            as={ExternalLink}
            eventKey='deals'
          />

          <HeaderItem
            className='d-none d-sm-flex align-items-center'
            href={legacyReportsUrl()}
            icon='report'
            text='Relatórios'
            as={ExternalLink}
            eventKey='reports'
          />

          <HeaderItemsDropdown dealsView={dealsView} />

        </Nav>

        {
          showOptout && !isSearchActive &&
          <>
            <OptoutButton
              className='me-0 me-xl-6'
              from={optoutFrom}
              entity={optoutEntity}
              onClick={handleOpenModal}
            />

            <BackToLegacyModal
              show={showLegacyModal}
              onHide={() => setShowLegacyModal(false)}
              variant={optoutFrom}
              entity={optoutEntity}
              entityType={optoutEntity}
            />
          </>
        }

        <HeaderSearch
          onClear={() => setIsSearchActive(false)}
          ref={searchRef}
          isSearchActive={isSearchActive}
          onClick={onClick}
        />

        <HeaderUser />

        <HeaderMenu />
      </Navbar>
    </>
  );
}

function getOptoutConfig(matchResource, matchRest) {
  let showOptout = false;
  let optoutFrom = null;
  let optoutEntity = null;

  const config = OPTOUT_CONFIGS[matchResource];

  if ((config?.exact && !matchRest) || (config && !config.exact)) {
    showOptout = true;
    optoutFrom = config.from;
    optoutEntity = config.entity;
  }

  return { showOptout, optoutFrom, optoutEntity };
}

function getDealsView(matchResource, matchRest) {
  if (matchResource === 'deals' && !matchRest) {
    return DEALS_VIEW_PAGES.list;
  }

  if (matchResource === 'funnels' && Number(matchRest)) {
    return DEALS_VIEW_PAGES.funnel;
  }

  return getDealsViewCookie();
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
