import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { checkoutPath } from '@/routes';
import { parseDate, toTextualDateString } from '@/date';

import Button from '@/components/Button';
import Icon from '@/components/Icon';

const propTypes = {
  type: PropTypes.oneOf(['blocked', 'expired']).isRequired,
  blockAccessDate: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired
};

function PaymentWarningCard({ type, blockAccessDate, expirationDate }) {
  const blockingDateFormatted = toTextualDateString(parseDate(blockAccessDate));
  const showButton = type !== 'blocked';

  const hideWhenExpiringToday = useMemo(() => {
    const parsedExpirationDate = parseDate(expirationDate);
    const now = new Date();
    const noon = 12;
    parsedExpirationDate.setHours(noon, 0, 0, 0);

    return now < parsedExpirationDate;
  }, [expirationDate]);

  const cardData = {
    blocked: {
      title: 'Conta bloqueada',
      buttonText: 'Renovar assinatura',
      message: (
        <>
          Sua assinatura expirou e não reconhecemos o seu pagamento. <br />
          Para continuar utilizando o Agendor, é necessário renovar a sua assinatura.
        </>
      ),
      iconColor: 'text-danger'
    },
    expired: {
      title: 'Evite o bloqueio de sua conta',
      buttonText: 'Gerar 2ª via boleto',
      message: (
        <>
          Não identificamos o pagamento. Regularize a assinatura
          até o dia {blockingDateFormatted}. <br />
          Caso já tenha pago o boleto, desconsidere essa mensagem.
        </>
      ),
      iconColor: ''
    }
  };

  if (hideWhenExpiringToday) {
    return null;
  }

  return (
    <div className='p-4 rounded mb-4 bg-danger bg-opacity-10'>
      <div className='p-0 d-flex align-items-center'>
        <div className='row w-100'>
          <div className={`ms-4 col-auto d-flex align-items-center ${cardData[type].iconColor}`}>
            <Icon name='alert' size='lg' />
          </div>
          <div className='col d-flex flex-column justify-content-center'>
            <h4>{cardData[type].title}</h4>
            <div className='text-dark-gray'>
              {cardData[type].message}
            </div>
          </div>
          <div className='col-auto d-flex flex-column align-items-end'>
            {showButton && (
              <Button
                href={checkoutPath()}
                variant='outline-dark-gray'
                className='w-100'
              >
                Renovar com Cartão de Crédito
              </Button>
            )}
            <Button
              href={checkoutPath()}
              variant='primary'
              className='mt-2 w-100'
            >
              {cardData[type].buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

PaymentWarningCard.propTypes = propTypes;

export default PaymentWarningCard;
