import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useStoredState } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import DetailsWhatsappContactList from '@/feature/whatsapp/DetailsWhatsappContactList';
import ModalAgendorChat from '@/components/WhatsApp/ModalAgendorChat';
import { WhatsappPopoverProvider } from '@/contexts/whatsappPopover';
import classNames from 'classnames';

DetailsWhatsApp.propTypes = {
  entity: PropTypes.object,
  relatedEntity: PropTypes.object,
  entityType: PropTypes.string,
  onUpdateEntity: PropTypes.func,
  className: PropTypes.string
};

DetailsWhatsApp.defaultProps = {
  entityType: '',
  entity: null,
  relatedEntity: null,
  onUpdateEntity: () => { },
  className: ''
};

function DetailsWhatsApp({
  className,
  entity,
  relatedEntity,
  entityType,
  onUpdateOrganization,
  onUpdatePerson,
  hasMarginTop
}) {
  const { user } = useAuth();
  const tracker = useTracking();
  const isBelow1200 = useMediaQuery('(max-width: 1300px)');
  const hasActiveAccountChat = user?.account?.accountChat?.active;

  const {
    value: hasShownModalOnce, store: setHasShownModalOnce
  } = useStoredState('details-whatsapp', false);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    tracker.trackActionButtonClicked({ user, action: 'Enviar whatsapp', entityType });

    if (!hasActiveAccountChat && !hasShownModalOnce) {
      tracker.trackWhatsappModalViewed({ user });
      setShowModal(true);
      setHasShownModalOnce(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Popover
        showArrow
        placement='left-start'
        className='z-index-1060 max-width-6'
        content={() => (
          <WhatsappPopoverProvider
            entity={entity}
            entityType={entityType}
            onUpdatePerson={onUpdatePerson}
            onUpdateOrganization={onUpdateOrganization}
            relatedEntity={relatedEntity}
          >
            <DetailsWhatsappContactList />
          </WhatsappPopoverProvider>
        )}
      >
        <Button
          className={classNames(
            className,
            'd-flex align-items-center text-nowrap w-100',
            { 'ms-0': isBelow1200, 'mt-2': hasMarginTop }
          )}
          onClick={handleClick}
        >
          <Icon name='whatsapp' className='ms-1 me-2' size='fixed' />
          <span>Enviar WhatsApp</span>
        </Button>
      </Popover>
      {
        showModal
          ? (
            <ModalAgendorChat show={showModal} onClose={handleCloseModal} />
          )
          : null
      }
    </>
  );
}

export default DetailsWhatsApp;
