import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import ProratedSummary from '@/components/Billing/ProratedSummary';

import { toCurrencyString } from '@/number';
import { useUpdateSubscriptionContext } from '@/contexts';

const propTypes = {
  periodicityText: PropTypes.string,
  renovationDate: PropTypes.string
};

const defaultProps = {
  periodicityText: '',
  renovationDate: ''
};

function UpdatedSubscriptionPreview({ renovationDate, periodicityText }) {
  const {
    selectedMaxUsers,
    updatedSubscriptionPreview,
    loadedSubscriptionPreview
  } = useUpdateSubscriptionContext();

  if (!loadedSubscriptionPreview || !updatedSubscriptionPreview) {
    return null;
  }

  const planFullName = `${updatedSubscriptionPreview.name} ${selectedMaxUsers}`;
  const proratedInfo = updatedSubscriptionPreview.proratedInfo;
  const isProrated = Boolean(proratedInfo);
  const proratedValue = isProrated ? proratedInfo.value : 0;

  return (
    <div className='animation-fade-in-delay'>
      <h5>Plano {planFullName}</h5>

      Próxima renovação

      <div>
        <span className='text-dark-gray'>Data de pagamento: </span>
        {renovationDate}
      </div>

      <div>
        <span className='text-dark-gray'>Período: </span>
        {capitalize(periodicityText)}
      </div>

      <div>
        <span className='text-dark-gray'>Valor: </span>
        {toCurrencyString(updatedSubscriptionPreview.finalValue)}
      </div>

      <hr className='my-1' />

      Pró-rata

      {isProrated && (
        <div>
          <span className='text-dark-gray'>Data de pagamento:</span> Hoje
        </div>
      )}

      <div>
        <span className='text-dark-gray'>Período: </span>
        {updatedSubscriptionPreview.remainingDays} dias
      </div>

      <div className='d-flex align-items-center'>
        <div className='me-1'>
          <span className='text-dark-gray'>Valor total: </span>
          {toCurrencyString(proratedValue)}
        </div>

        {isProrated && (
          <ProratedSummary
            remainingDays={updatedSubscriptionPreview.remainingDays}
            planFullName={proratedInfo.planFullName}
            currentPlanFullName={proratedInfo.currentPlanFullName}
            valuePerMonth={proratedInfo.valuePerMonth}
            currentValuePerMonth={proratedInfo.currentValuePerMonth}
            diffValuePerMonth={proratedInfo.diffValuePerMonth}
            diffValuePerDay={proratedInfo.diffValuePerDay}
            value={proratedInfo.value}
            valueWithoutCustomDiscount={proratedInfo.valueWithoutCustomDiscount}
            currentDiscounts={updatedSubscriptionPreview.currentDiscounts}
            prorateDiscounts={updatedSubscriptionPreview.prorateDiscounts}
            periodicityText={capitalize(periodicityText)}
          />
        )}
      </div>
    </div>
  );
}

UpdatedSubscriptionPreview.propTypes = propTypes;
UpdatedSubscriptionPreview.defaultProps = defaultProps;

export default UpdatedSubscriptionPreview;
