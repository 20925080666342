import React from 'react';
import HeaderAccountExpirationWarning, {
  useHeaderAccountExpirationWarning
} from '@/components/HeaderAccountExpirationWarning';
import HeaderPaymentMethod, { useHeaderPaymentMethod } from '@/components/HeaderPaymentMethod';
import HeaderAccountBlockedWarning, {
  useHeaderAccountBlockedWarning
} from '@/components/HeaderAccountBlockedWarning';
import HeaderAccountBlockingWarning, {
  useHeaderAccountBlockingWarning
} from '@/components/HeaderAccountBlockingWarning';

import { useCurrentPlan } from '@/api';
import { useAuth } from '@/lib/auth';

function HeaderWarning() {
  const { user } = useAuth();
  const { isLoading, data: { data: currentPlanData } = {} } = useCurrentPlan({
    config: { enabled: user.admin }
  });

  const {
    show: showAccountBlockedWarning, onClose: onCloseAccountBlockedWarning
  } = useHeaderAccountBlockedWarning(currentPlanData);
  const {
    show: showAccountExpirationWarning, onClose: onCloseAccountExpirationWarning
  } = useHeaderAccountExpirationWarning(currentPlanData);
  const {
    show: showAccountBlockingBlockWarning, onClose: onCloseAccountBlockingWarning
  } = useHeaderAccountBlockingWarning(currentPlanData);
  const {
    show: showPaymentMethod, onClose: onClosePaymentMethod
  } = useHeaderPaymentMethod(currentPlanData);

  let HeaderComponent;
  let onClose;

  if (showAccountBlockedWarning) {
    HeaderComponent = HeaderAccountBlockedWarning;
    onClose = onCloseAccountBlockedWarning;
  } else if (showAccountExpirationWarning) {
    HeaderComponent = HeaderAccountExpirationWarning;
    onClose = onCloseAccountExpirationWarning;
  } else if (showAccountBlockingBlockWarning) {
    HeaderComponent = HeaderAccountBlockingWarning;
    onClose = onCloseAccountBlockingWarning;
  } else if (showPaymentMethod) {
    HeaderComponent = HeaderPaymentMethod;
    onClose = onClosePaymentMethod;
  }

  if (isLoading || !HeaderComponent) {
    return null;
  }

  return (
    <HeaderComponent currentPlanData={currentPlanData} onClose={onClose} />
  );
}

export default HeaderWarning;
