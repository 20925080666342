/* eslint-disable complexity */
import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { canFinishActivity } from '@/policies';
import {
  ACTIVITY_TYPES,
  EMAIL_STATUSES,
  ENTITIES_TRACKER_SCREEN,
  getPhonesOnlyNumbers
} from '@/utils';
import {
  formatDate,
  formatTime,
  toDateString,
  parseDate,
  toUTCISOString,
  isPast,
  getTimelineDateLabel,
  fixDateYear
} from '@/date';
import addHours from 'date-fns/addHours';
import {
  useActivity,
  useUpdateHistoricSuggestion,
  useUpdateHistoricSuggestionsFromActivity
} from '@/api';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import Button from '@/components/Button';
import AvatarList from '@/components/AvatarList';
import TruncateLinkfied from '@/components/TruncateLinkfied';
import Authorization from '@/components/Authorization';
import Form from '@/components/EntityModal/Activities/Form';
import { canUpdateActivity, canDeleteActivity } from '@/policies/activities';
import strings from '@/strings';
import LoadingButton from '@/components/LoadingButton';
import Popover from '@/components/Popover';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { Checkbox } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import FileList from '@/components/FileList';
import OriginalHeader from '@/components/EntityModal/Activities/OriginalHeader';
import ActivityCreatedBy from '@/components/EntityModal/Activities/ActivityCreatedBy';
import EmailDetailPopover from '@/components/Email/EmailDetailPopover';
import EmailStatusBadge from '@/components/Email/EmailStatusBadge';
import EmailBodyContent from '@/components/Email/EmailBodyContent';
import EmailActionButtons from '@/components/Email/EmailActionButtons';
import SmartSummary from '@/components/SmartSummary';
import SmartSuggestion from '@/components/EntityModal/Activities/SmartSuggestion';
import SmartSuggestionButton from '@/components/EntityModal/Activities/SmartSuggestionButton';

function Activity({
  activity,
  suggestions,
  onUpdate,
  onDelete,
  entity,
  relatedEntity,
  showEmailHistory,
  onEditEntity,
  entityName,
  onSelectIntent
}) {
  const entities = compact([relatedEntity].concat(relatedEntity?.people || []));
  const intents = prepareIntents(activity, suggestions, entities);
  const isProcessing = Boolean(intents?.find(({ processing }) => processing === true));
  const isOpen = Boolean(intents?.find(({ open }) => open === true));

  const suggestionsParams = { entity: entityName, entity_id: entity.id };
  const updateSuggestionsMutation = useUpdateHistoricSuggestion({ params: suggestionsParams });
  const updateSuggestionsFromActivityMutation = useUpdateHistoricSuggestionsFromActivity();

  const { user } = useAuth();
  const tracker = useTracking();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showEditDeleteButtons, setShowEditDeleteButtons] = useState('hidden');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSmartSuggestion, setShowSmartSuggestion] = useState(!isProcessing && isOpen);

  const email = activity.email ?? {};
  const emailFrom = email.from;
  const isEmailReceived = email.status === EMAIL_STATUSES.RECEIVED;
  const isEmailFailed = email.status === EMAIL_STATUSES.FAILED;
  const isNote = activity.type === ACTIVITY_TYPES.NOTE;
  const isPerformedEmail = Boolean(activity.email);
  /*
   *Idealmente usamos a data em UTC, porém, há casos onde atividades possuem dueAt, mas não dueAtUtc
   *Será corrigido este comportamento no banco de dados em WEB-8057
   */

  const dueAt = fixDateYear(
    activity.dueAtUtc
      ? activity.dueAtUtc
      : activity.dueAt
  );
  const finishedAt = fixDateYear(
    activity.finishedAtUtc
      ? activity.finishedAtUtc
      : activity.finishedAt
  );

  const createdAt = parseDate(activity.createdAt);

  const isActivityFinished = Boolean(activity.finished && finishedAt);
  const isActivityLate = dueAt && !isActivityFinished && isPast(dueAt);
  const isActivityOnSchedule = dueAt && !isActivityFinished && !isPast(dueAt);
  const finishedByName = activity?.finishedBy?.name;
  const finishedByTooltip = isActivityFinished && finishedByName
    ? `Finalizada por ${finishedByName}`
    : '';

  const hasDueAtError = dueAt && activity.dueAt && !activity.dueAtUtc;
  const hasFinishedAtError = finishedAt && activity.finishedAt && !activity.finishedAtUtc;
  const FAKE_UTC_HOURS = 3;

  const parsedDueAt = hasDueAtError
    ? addHours(parseDate(dueAt), FAKE_UTC_HOURS)
    : parseDate(dueAt);
  const parsedFinishedAt = hasFinishedAtError
    ? addHours(parseDate(finishedAt), FAKE_UTC_HOURS)
    : parseDate(finishedAt);

  const dueAtLabel = dueAt && getTimelineDateLabel(parsedDueAt);
  const finishedAtLabel = finishedAt && getTimelineDateLabel(parsedFinishedAt);
  const createdAtLabel = createdAt && getTimelineDateLabel(createdAt);

  const handleDelete = () => {
    setIsSubmitting(true);
    onDelete(activity.id, () => {
      setIsSubmitting(false);
    });
  };

  const {
    data: { data: activityFromApi } = {},
    isLoading: isLoadingActivity
  } = useActivity({
    activityId: activity?.id,
    config: { enabled: showEditForm, staleTime: 1000 }
  });

  const activeSmartSuggestion = Boolean(
    user?.features?.smartSuggestionsEnabled &&
    (isActivityFinished || isNote) &&
    suggestions?.length
  );

  const initialValues = useMemo(() => {
    if (showEditForm) {
      return prepareInitialValues(activityFromApi);
    }
    return prepareInitialValues(activity);
  }, [activity, activityFromApi]);

  const typeState = isActivityFinished ? 'type_finished' : 'type_started';

  const activityHeaderData = useMemo(() => {
    if (isEmailReceived) {
      return {
        icon: 'email-received',
        title: 'E-mail recebido',
        label: 'Recebida'
      };
    }

    if (isEmailFailed) {
      return {
        icon: 'email-sent',
        title: 'E-mail não enviado',
        label: 'Enviada'
      };
    }

    return {
      icon: activity.type,
      title: strings.models.activity[typeState][activity.type],
      label: 'Criada'
    };
  }, [isEmailReceived, isEmailFailed, activity.type, typeState]);

  const handleFinishedClick = () => {
    setIsUpdating(true);
    onUpdate(
      activity?.id,
      {
        finished_at: isActivityFinished ? null : toUTCISOString(new Date()),
        text: activity.text,
        type: activity.type
      },
      (error) => {
        setIsUpdating(false);
        if (!error) {
          tracker.trackTaskFinished({ user, entityType: entityName });
        }
      }
    );
  };

  const handleUpdateSuggestion = (currentIntent, params) => {
    updateSuggestionsMutation.mutate({ id: currentIntent.id, params });
  };

  const handleUpdateActivitySuggestions = (open) => {
    setShowSmartSuggestion(open);

    const params = { open, activity_id: activity.id };
    updateSuggestionsFromActivityMutation.mutate({ params });
  };

  useEffect(() => {
    setShowSmartSuggestion(!isProcessing && isOpen);
  }, [suggestions]);

  if (showEditForm) {
    return (
      <div className='rounded bg-light'>
        <div className='d-flex justify-content-between p-3'>
          <h4 className='mt-1 text-darker-gray'>Editar atividade</h4>
          <Button
            className='text-dark-gray p-0'
            onClick={() => setShowEditForm(false)}
            variant='link'
          >
            <Icon name='close' />
          </Button>
        </div>
        <Form
          entityId={entity?.id}
          entityName={entityName}
          activityId={activity.id}
          initialValues={initialValues}
          isLoading={isLoadingActivity}
          onUpdate={onUpdate}
          onClose={() => setShowEditForm(false)}
        />
      </div>
    );
  }

  return (
    <div
      className='rounded border border-light-gray bg-light'
      onMouseEnter={() => setShowEditDeleteButtons('visible')}
      onMouseLeave={() => setShowEditDeleteButtons('hidden')}
      data-activity-id={activity?.id}
    >
      <OriginalHeader activity={activity} entity={entity} />

      <div className='px-4 py-3 text-darker-gray fw-bold'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex'>
            <div
              className={classnames(
                'me-3 p-1 rounded bg-lighter-blue',
                'text-dark-gray lh-0 position-relative'
              )}
            >
              <Icon name={activityHeaderData.icon} />
            </div>

            <div className='me-4 flexible-width'>
              <span>{activityHeaderData.title}</span>
              <Truncate className='text-small fw-normal text-dark-gray'>
                {activityHeaderData.label} {createdAtLabel.toLowerCase()}
              </Truncate>
            </div>
          </div>

          <div className='d-flex align-items-center'>
            {
              (isActivityLate || isActivityOnSchedule) && (
                <span
                  className={classnames(
                    'text-nowrap text-small px-2 py-1 rounded me-2',
                    { 'bg-light-yellow text-warning': isActivityLate },
                    { 'bg-light-green text-success': isActivityOnSchedule }
                  )}
                >
                  Prazo: {dueAtLabel}
                </span>
              )
            }

            {
              !isNote && (
                <Tooltip
                  placement='top'
                  className='my-n3'
                  content={finishedByTooltip}
                  hide={!finishedByTooltip}
                >
                  <div>
                    <Authorization
                      policy={canFinishActivity}
                      data={activity}
                    >
                      <Checkbox
                        id={`activity_finished_${activity.id}`}
                        name='activity_finished'
                        checked={isActivityFinished}
                        onChange={handleFinishedClick}
                        className='ms-auto mt-3 me-2 form-check-input.is-valid'
                        disabled={isUpdating}
                        isValid
                        label={
                          <span
                            className={classnames(
                              'text-dark-gray',
                              { 'cursor-pointer': !isUpdating },
                              { 'text-decoration-line-through': isActivityFinished }
                            )}
                          >
                            {isActivityFinished
                              ? `Finalizada ${finishedAtLabel.toLowerCase()}`
                              : 'Finalizar'}
                          </span>
                        }
                      />
                    </Authorization>
                  </div>
                </Tooltip>
              )
            }
          </div>
        </div>
      </div>
      <hr className='m-0' />
      <div className='rounded-bottom bg-white pt-2 pb-2 px-4'>
        {
          isPerformedEmail
            ? (
              <>
                <Row className='row mb-3'>
                  <Col className='col-9'>
                    <span>Assunto:</span>
                    <span className='fw-bold text-nowrap ms-1'>{email.subject}</span>
                    <EmailDetailPopover email={email} />
                  </Col>
                  <Col className='col-3 text-end'>
                    <EmailStatusBadge status={email.status} statusMessage={email.statusMessage} />
                  </Col>
                </Row>

                <Row className='row mb-3'>
                  <Col className='d-flex align-items-center'>
                    Enviado por:
                    <div className='ms-1 d-inline-flex align-items-center'>
                      {
                        isEmailReceived
                          ? (
                            <div>
                              <Icon name='email-received' className='me-1' />
                              {emailFrom}
                            </div>
                          )
                          : (
                            <ActivityCreatedBy
                              createdBy={activity.createdBy}
                              label
                            />
                          )
                      }
                    </div>
                  </Col>
                </Row>
                <hr className='mb-2'/>
                <Row>
                  <Col>
                    <EmailBodyContent email={email} />
                  </Col>
                </Row>
              </>
            )
            : (
              <>
                <TruncateLinkfied
                  fullWidth
                  anchorClassName='d-block fw-bold'
                  text={activity.text}
                  maxLength={200}
                />
                <SmartSummary activity={activity} />
              </>
            )
        }
        <FileList documents={activity.documents} images={activity.images} />
        <div className='d-flex justify-content-between mt-2'>
          {
            isPerformedEmail
              ? (
                <>
                  <EmailActionButtons
                    entityId={entity.id}
                    entityType={entityName}
                    email={email} className='ms-n3'
                  />
                  {
                    entityName === 'deal' && (
                      <Tooltip content='Lista de emails'>
                        <Button
                          className='px-1 ms-auto text-dark-gray'
                          variant='link'
                          style={{ visibility: showEditDeleteButtons }}
                          onClick={showEmailHistory}
                        >
                          <Icon name='expand' size='sm'/>
                        </Button>
                      </Tooltip>
                    )
                  }
                </>
              )
              : (
                <>
                  <div className='d-flex align-items-center'>
                    <span className='me-1 text-small text-dark-gray'>
                      Criada por
                    </span>
                    <ActivityCreatedBy createdBy={activity?.createdBy} />

                    {
                      activity?.assignedUsers.length
                        ? (
                          <>
                            <div className='vr mx-2'></div>
                            <span className='me-1 text-small text-dark-gray'>Responsáveis</span>
                            <AvatarList
                              disableArrow
                              slide={false}
                              items={activity?.assignedUsers.map((assignedUser) => ({
                                name: assignedUser.name,
                                url: assignedUser.avatarUrl
                              }))}
                            />
                          </>
                        )
                        : null
                    }
                  </div>
                  <Authorization policy={canUpdateActivity}>
                    <Tooltip content='Editar'>
                      <Button
                        className='ms-auto text-dark-gray'
                        style={{ visibility: showEditDeleteButtons }}
                        variant='link'
                        onClick={() => setShowEditForm(true)}
                        aria-label='Editar'
                      >
                        <Icon name='edit' size='sm'/>
                      </Button>
                    </Tooltip>
                  </Authorization>
                </>
              )
          }

          <Popover
            rootCloseEvent='mousedown'
            placement='bottom'
            showArrow
            contentClassName='pt-4 pb-4 px-3'
            content={({ onHide }) => (
              <>
                <h4 className='ms-3 me-3 mb-3 text-darker-gray fw-bold'>
                  Deseja realmente excluir esta <br /> atividade?
                </h4>
                <Button
                  className='ms-3 me-3'
                  variant='light'
                  onClick={onHide}
                >
                  Agora não
                </Button>
                <LoadingButton
                  className='me-3'
                  isLoading={isSubmitting}
                  variant='danger'
                  type='submit'
                  onClick={handleDelete}
                  loadingText='Excluindo...'
                >
                  Sim, excluir
                </LoadingButton>
              </>
            )}
          >
            <div>
              <Authorization policy={canDeleteActivity}>
                <Tooltip content='Excluir'>
                  <Button
                    className='text-dark-gray me-n2'
                    variant='link'
                    style={{ visibility: showEditDeleteButtons }}
                  >
                    <Icon name='delete' size='sm' />
                  </Button>
                </Tooltip>
              </Authorization>
            </div>
          </Popover>
          <SmartSuggestionButton
            show={showSmartSuggestion}
            active={activeSmartSuggestion}
            loading={isProcessing}
            onClick={() => handleUpdateActivitySuggestions(!showSmartSuggestion)}
          />
        </div>
      </div>
      <SmartSuggestion
        activity={activity}
        entities={entities}
        relatedEntity={relatedEntity}
        entityScreen={ENTITIES_TRACKER_SCREEN[entityName]}
        onEditEntity={onEditEntity}
        onSelectIntent={onSelectIntent}
        intents={intents}
        active={activeSmartSuggestion}
        show={showSmartSuggestion && !isProcessing}
        onSave={(currentIntent) => {
          const params = { processing: false, confirmed: true, cancelled: false, open: false };
          handleUpdateSuggestion(currentIntent, params);
        }}
        onCancel={(currentIntent) => {
          const params = { processing: false, confirmed: false, cancelled: true, open: false };
          handleUpdateSuggestion(currentIntent, params);
        }}
        onClose={() => handleUpdateActivitySuggestions(false)}
      />
    </div>
  );
}

function prepareIntents(activity, suggestions, entities) {
  if (activity && suggestions?.length) {
    let extractedPhones = [];
    let extractedEmails = [];

    if (entities?.length) {
      const currentPhones = compact(
        flatten(
          map(
            entities,
            ({ contact }) => map(
              getPhonesOnlyNumbers(contact),
              (phone) => phone?.replace(/^\+55/gu, '')?.replace(/\D/gu, '')
            )
          )
        )
      );

      const currentEmails = compact(
        map(entities, (entityItem) => entityItem?.contact?.email)
      );

      extractedPhones = suggestions
        .filter(({ suggestionType }) => suggestionType === 'contact.phone')
        .filter(({ capturedText }) => !currentPhones.includes(capturedText));

      extractedEmails = suggestions
        .filter(({ suggestionType }) => suggestionType === 'contact.email')
        .filter(({ capturedText }) => !currentEmails.includes(capturedText));
    }

    const extractedActivities = suggestions
      .filter(({ suggestionType }) => suggestionType?.includes('activity.'));

    const extracted = [
      ...extractedPhones,
      ...extractedEmails,
      ...extractedActivities
    ];
    const intents = extracted.map((suggestion) => ({
      id: suggestion.id,
      type: suggestion.suggestionType,
      value: suggestion.capturedText,
      suggestionData: JSON.parse(suggestion.suggestionData || '{}'),
      processing: Boolean(suggestion?.processing),
      open: Boolean(suggestion?.open),
      activity
    }));

    return intents;
  }

  return [];
}


function prepareInitialValues(data = {}) {
  const type = data?.type ?? ACTIVITY_TYPES.TASK;
  const text = data?.text ?? '';
  const date = formatDate(data?.dueAtUtc) || toDateString(new Date());
  const time = data?.allDay ? '' : formatTime(data?.dueAtUtc) ?? '';
  const assignedUserIds = data?.assignedUsers?.map((assignedUser) => assignedUser.id) ?? [];
  const attachments = compact([].concat(data?.documents).concat(data?.images));
  const finishedAt = data?.finishedAt ?? '';
  return { text, date, time, assignedUserIds, attachments, type, finishedAt };
}

export default Activity;
