import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { parseDate, toTextualDateString } from '@/date';
import { toCurrencyString } from '@/number';

const propTypes = {
  periodicity: PropTypes.oneOf(['monthly', 'quarterly', 'half_yearly', 'yearly']).isRequired,
  finalValue: PropTypes.number.isRequired,
  expirationDate: PropTypes.string.isRequired,
  maxUsersCount: PropTypes.number,
  monthlyInfo: PropTypes.shape({
    value: PropTypes.number,
    valuePerUser: PropTypes.number,
    valueWithoutDiscount: PropTypes.number
  })
};

const periodicityText = {
  monthly: 'mensal',
  quarterly: 'trimestral',
  half_yearly: 'semestral',
  yearly: 'anual'
};

function CurrentPlanPaymentSummary({
  periodicity,
  finalValue,
  expirationDate,
  maxUsersCount,
  monthlyInfo
}) {
  const monthlyValuePerUserFormatted = toCurrencyString(monthlyInfo.valuePerUser);
  const expirationDateFormatted = toTextualDateString(parseDate(expirationDate));
  const finalValueFormatted = toCurrencyString(finalValue);

  return (
    <div
      id='payment-summary'
      className={
        classnames(
          'w-100',
          'text-small',
          'bg-light'
        )
      }
    >
      <h4 className='mt-2 mb-4'>Resumo</h4>

      <RowContent
        title={`Assinatura ${periodicityText[periodicity]}`}
        content={`Até ${expirationDateFormatted}`}
      />

      <RowContent
        title='Quantidade de usuários'
        content={maxUsersCount}
      />

      <RowContent
        title='Valor por usuário'
        content={monthlyValuePerUserFormatted}
      />

      <hr className='mt-3 mb-2 opacity-100' />

      <div className='mt-2 d-flex align-items-baseline justify-content-between mb-1'>
        <h4 className='text-dark-gray'>Valor total</h4>

        <h5>{finalValueFormatted}</h5>
      </div>
    </div>
  );
}

function RowContent({ title, content }) {
  return (
    <div className='d-flex align-items-baseline justify-content-between mb-1'>
      <div className='fw-bold text-dark-gray me-8'>{title}</div>

      <div className='ms-2'>{content}</div>
    </div>
  );
}

CurrentPlanPaymentSummary.propTypes = propTypes;

export default CurrentPlanPaymentSummary;
