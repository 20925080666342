import React, { useEffect, useMemo, useState } from 'react';
import Badge from '@/components/Badge';
import Icon from '@/components/Icon';
import { TextInput } from '@/components/Inputs';
import { useCheckoutContext } from '@/contexts';
import { useCheckCoupon } from '@/api';
import Button from '@/components/Button';
import LoadSpinner from '@/components/LoadSpinner';

function CouponInput() {
  const { usersCount, period, couponCode, setCoupon } = useCheckoutContext();

  const [code, setCode] = useState(couponCode || '');

  const checkCouponMutation = useCheckCoupon();
  const { isError, error, isLoading, data } = checkCouponMutation;

  const applyCoupon = () => {
    if (code.trim().length === 0) {
      return;
    }

    const params = {
      code,
      users_count: usersCount,
      period
    };
    checkCouponMutation.mutate(params, {
      onSuccess: ({ data: couponData }) => {
        setCoupon(couponData);
      },
      onError: () => {
        setCoupon();
      }
    });
  };

  const clearCoupon = () => {
    setCode('');
    setCoupon();
    checkCouponMutation.reset();
  };

  const isCouponApplied = Boolean(data);

  const feedbackInfo = useMemo(() => {
    if (!data && !error) {
      return null;
    }
    if (data) {
      return 'Cupom aplicado com sucesso.';
    }

    const [status] = error.data.errors.status;

    const messages = {
      max_usages_reached: 'Desculpe, o cupom que você informou atingiu o limite de usos.',
      expired: 'Desculpe, o cupom que você informou já expirou.',
      not_found: 'Desculpe, não encontramos o cupom que você informou.',
      already_used: 'Desculpe, o cupom que você informou já foi utilizado.'
    };
    return messages[status];
  }, [data, error]);

  useEffect(() => {
    if (code) {
      applyCoupon();
    }
  }, []);

  return (
    <>
      <div className='d-flex mb-1'>
        <TextInput
          disabled={isCouponApplied}
          value={code}
          onChange={setCode}
          name='coupon'
          className='mb-0 me-1'
          placeholder='Digite o cupom aqui' />

        <Button
          className='align-self-start'
          disabled={isLoading}
          variant={isCouponApplied ? 'light' : 'primary'}
          onClick={isCouponApplied ? clearCoupon : applyCoupon}
        >
          {isLoading
            ? <LoadSpinner className='text-white opacity-100' />
            : <Icon name={isCouponApplied ? 'delete' : 'check'} />
          }
        </Button>
      </div>

      {!isLoading && (
        <div className='d-flex flex-column'>
          <Badge
            className='align-self-start mb-1'
            variant={!isError ? 'light-green' : 'light-red'}
          >
            {feedbackInfo}
          </Badge>
        </div>
      )}
    </>
  );
}

export default CouponInput;
