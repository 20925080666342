import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import Details from '@/components/EntityModal/Deal/Details';
import EntityName from '@/components/EntityModal/Deal/EntityName';
import Menu from '@/components/EntityModal/Menu';
import StatusBadge from '@/components/EntityModal/Deal/StatusBadge';
import StatusButtons from '@/components/EntityModal/Deal/StatusButtons';
import StatusDescription from '@/components/EntityModal/Deal/StatusDescription';
import StagesButtons from '@/components/EntityModal/Deal/StagesButtons';
import RestrictWarningBadge from '@/components/EntityModal/RestrictWarningBadge';
import DotList from '@/components/DotList';
import Icon from '@/components/Icon';
import Rating from '@/components/Rating';
import {
  CustomerSelect,
  EditInPlace,
  FunnelSelect
} from '@/components/Inputs';
import Truncate from '@/components/Truncate';
import DealActivities from '@/components/EntityModal/Deal/Activities/DealActivities';
import Skeleton from '@/components/Skeleton';
import { canEditDeal, canEditDealStage } from '@/policies';
import RequiredFieldsModal, { useRequiredFieldsModal } from '@/components/RequiredFieldsModal';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Freezing from '@/components/EntityModal/Deal/Freezing';
import EntityTitle from '@/components/EntityModal/EntityTitle';
import SelectOwnerUser from '@/components/EntityModal/SelectOwnerUser';
import { dealEditPath } from '@/routes';
import { useScreenSize } from '@/hooks';

ModalContent.defaultProps = {
  deal: {},
  dealCustomFields: [],
  historicSuggestions: [],
  voip: {},
  entity: {},
  entityType: '',
  entityCustomFields: [],
  stages: [],
  activities: [],
  isFetchingActivities: false,
  isFetchingNextPageActivities: false,
  fetchNextPageActivities: () => { },
  hasNextPageActivities: false,
  loading: false,
  onCreateProposal: () => { },
  onCreateActivity: () => { },
  onUpdateActivity: () => { },
  onDeleteActivity: () => { },
  onDelete: () => { },
  onUpdate: () => { },
  isOrganizationSuccess: false,
  isPersonSuccess: false,
  onRefreshModal: () => {},
  onUpdateEntity: () => {}
};

ModalContent.propTypes = {
  deal: PropTypes.object,
  dealCustomFields: PropTypes.array,
  historicSuggestions: PropTypes.array,
  voip: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityCustomFields: PropTypes.array,
  stages: PropTypes.array,
  activities: PropTypes.array,
  isFetchingActivities: PropTypes.bool,
  isFetchingNextPageActivities: PropTypes.bool,
  fetchNextPageActivities: PropTypes.func,
  hasNextPageActivities: PropTypes.bool,
  loading: PropTypes.bool,
  onCreateProposal: PropTypes.func,
  onCreateActivity: PropTypes.func,
  onUpdateActivity: PropTypes.func,
  onDeleteActivity: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  isOrganizationSuccess: PropTypes.bool,
  isPersonSuccess: PropTypes.bool,
  onRefreshModal: PropTypes.func,
  onUpdateEntity: PropTypes.func
};

function ModalContent({
  deal,
  dealCustomFields,
  historicSuggestions,
  voip,
  stages,
  activities,
  isFetchingActivities,
  isFetchingNextPageActivities,
  fetchNextPageActivities,
  hasNextPageActivities,
  entity,
  entityType,
  entityCustomFields,
  loading,
  onDelete,
  onUpdate,
  onCreateProposal,
  onCreateActivity,
  onUpdateActivity,
  onDeleteActivity,
  isOrganizationSuccess,
  isPersonSuccess,
  onRefreshModal,
  onUpdateEntity
}) {
  const [funnelSelectedId, setFunnelSelectedId] = useState(0);
  const { user } = useAuth();
  const tracker = useTracking();
  const { isMD, isXL } = useScreenSize();

  useEffect(() => {
    if (!funnelSelectedId) {
      setFunnelSelectedId(deal?.funnel?.id);
    }
  }, [deal?.funnel?.id]);

  const handleOnFunnelSelect = (funnelId) => {
    setFunnelSelectedId(funnelId);

    onUpdate({ funnel_id: funnelId }, (err) => {
      if (!err) {
        tracker.trackDealFunnelChanged({ user, page: 'Nova ficha' });
      }
    });
  };

  const {
    check: checkRequiredFields,
    ...requiredFieldsModal
  } = useRequiredFieldsModal();

  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;

  const handleRequiredFields = async (params, done) => {
    const funnel = deal.funnel;
    if (funnel?.hasRequiredFields && hasAccess) {
      const dealHasRequiredFields = await checkRequiredFields(deal, {
        deal_id: deal.id,
        funnel_id: deal.funnel.id,
        loss_reason_id: params?.deal_loss_reason?.loss_reason_id,
        loss_reason_description: params?.deal_loss_reason?.description,
        ...params
      });

      if (dealHasRequiredFields) {
        done?.();
        return;
      }
    }

    onUpdate(params, done);
  };

  const handleStageButtonClick = (stageId) => {
    if (deal.stage.id !== stageId) {
      handleRequiredFields({ stage_id: stageId }, (err) => {
        if (!err) {
          tracker.trackDealStageChanged({ user, page: 'Nova ficha' });
        }
      });
    }
  };

  const onCancel = () => {
    requiredFieldsModal.onHide();
  };

  const MAX_COL = 12;
  const MEDIUM_COL = 8;

  const handleCustomerUpdate = (params, done) => {
    const customer = params?.customer?.id.split('-');
    const entityKey = `${customer.at(0)}_id`;
    const id = Number(customer.at(1));
    const previousEntityKey = entityKey === 'person_id' ? 'organization_id' : 'person_id';

    onUpdate({ [entityKey]: id, [previousEntityKey]: null }, done);
  };

  return (
    <div>
      <header className='bg-white rounded'>
        <section className='px-4 pt-4'>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <div className='d-flex justify-content-start w-75 align-items-center'>
              {loading
                ? <Skeleton width={280} height={20} />
                : <EntityTitle
                  editName='title'
                  classNames='mb-0 min-width-5 d-inline'
                  entity={deal}
                  onUpdate={onUpdate}
                  isDeal
                />
              }
              <StatusBadge
                status={deal?.dealStatus?.key}
                finishedAt={deal?.finishedAt}
                loading={loading}
              />
            </div>
            <RestrictWarningBadge deal={deal} loading={loading} />
          </div>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            {
              isMD
                ? <div></div>
                : (
                  <div className='d-flex justify-content-start'>
                    {loading
                      ? (
                        <DotList
                          className='d-flex'
                          items={[
                            <Skeleton width={170} height={20} key={1} />,
                            <Skeleton width={110} height={20} key={2} />,
                            <Skeleton width={20} height={20} circle key={3} />
                          ]}
                        ></DotList>
                      )
                      : (
                        <>
                          <EditInPlace
                            as={CustomerSelect}
                            name='customer'
                            className='ms-2 me-5 mt-n1'
                            isCustomerSelect
                            isSelect
                            isEditInPlace
                            onConfirm={(params, done) => handleCustomerUpdate(params, done)}
                          >
                            <EntityName
                              isSuccess={isPersonSuccess || isOrganizationSuccess}
                              entity={entity}
                              deal={deal}
                            />
                          </EditInPlace>
                          <DotList
                            className='d-flex ms-3 text-medium-gray overflow-visible'
                            items={[
                              <Rating
                                key={1}
                                value={deal?.ranking ?? 0}
                                onChange={(ranking) => onUpdate({ ranking })}
                              />,
                              <SelectOwnerUser
                                key={2}
                                ownerUser={deal?.ownerUser}
                                canEditPolicy={canEditDeal}
                                onUpdate={onUpdate}
                              />
                            ].filter(Boolean)}
                          />
                        </>
                      )}
                  </div>
                )
            }
            <div className={classnames(
              'd-inline-flex',
              { 'justify-content-between w-100': isMD },
              { 'justify-content-end': !isMD }
            )}>
              <StatusButtons
                deal={deal}
                loading={loading}
                onChangeStatus={(params, done) => {
                  handleRequiredFields(params, done);
                }}
              />
              <Menu
                entity={deal}
                loading={loading}
                onDelete={onDelete}
                editPath={dealEditPath(deal?.id)}
                entityType='deal'
              />
            </div>
          </div>
          <Freezing deal={deal} loading={loading} className='d-flex mb-3' />
          <StatusDescription deal={deal} loading={loading} className='d-flex mb-4' />
        </section>
        <hr />
        <section className='px-4 py-3'>
          <>
            <div className='d-flex'>
              <div className={classnames(
                'my-2 text-nowrap',
                { 'width-5': isXL },
                { 'px-1 entity-modal-width': !isXL }
              )}>
                {!loading
                  ? (
                    <EditInPlace
                      as={FunnelSelect}
                      name='funnel_id'
                      isSelect
                      label=''
                      menuListClassName='max-width-6 width-5 bg-white'
                      className={classnames(
                        'text-darker-gray mt-n2',
                        { 'w-100': !isXL },
                        { 'w-75': isXL }
                      )}
                      leftAdornment={<Icon name='funnel' />}
                      value={funnelSelectedId}
                      onConfirm={(params, done) => handleOnFunnelSelect(params.funnel_id, done)}
                      policy={canEditDealStage}
                    >
                      <Icon name='funnel' />
                      <Truncate as='div' className='ms-1'>
                        {deal?.funnel?.name ?? ''}
                      </Truncate>
                    </EditInPlace>
                  )
                  : (
                    <Skeleton width={150} height={30} />
                  )}
              </div>
              {
                !loading
                  ? (
                    <>
                      <StagesButtons
                        onClick={handleStageButtonClick}
                        deal={deal}
                        stages={stages}
                      />
                      <RequiredFieldsModal
                        {...requiredFieldsModal}
                        onHide={onCancel}
                      />
                    </>
                  )
                  : (
                    <Skeleton
                      containerClassName='width-7 ms-9 my-2'
                      width='100%'
                      height={30}
                    />
                  )
              }
            </div>
          </>
        </section>
      </header>

      <main className='mb-2 p-4'>
        <Row>
          <Col sm={isMD ? MAX_COL : MEDIUM_COL}>
            <DealActivities
              deal={deal}
              activities={activities}
              historicSuggestions={historicSuggestions}
              voip={voip}
              dealCustomFields={dealCustomFields}
              entity={entity}
              entityType={entityType}
              entityCustomFields={entityCustomFields}
              loading={loading}
              isFetchingActivities={isFetchingActivities}
              isFetchingNextPageActivities={isFetchingNextPageActivities}
              fetchNextPageActivities={fetchNextPageActivities}
              hasNextPageActivities={hasNextPageActivities}
              onCreateActivity={onCreateActivity}
              onUpdateActivity={onUpdateActivity}
              onDeleteActivity={onDeleteActivity}
              onUpdateDeal={onUpdate}
              onEditEntity={onUpdateEntity}
            />
          </Col>

          {
            isMD
              ? null
              : (
                <Col className='ps-2' sm={4}>
                  <aside>
                    <Details
                      deal={deal}
                      dealCustomFields={dealCustomFields}
                      voip={voip}
                      entity={entity}
                      entityType={entityType}
                      entityCustomFields={entityCustomFields}
                      canEditPolicy={canEditDeal}
                      loading={loading}
                      onCreateProposal={onCreateProposal}
                      onCreateActivity={onCreateActivity}
                      onUpdateActivity={onUpdateActivity}
                      onRefreshModal={onRefreshModal}
                      onEditDeal={onUpdate}
                      onEditEntity={onUpdateEntity}
                      entityEditPath={dealEditPath(deal?.id)}
                      isOrganizationSuccess={isOrganizationSuccess}
                      isPersonSuccess={isPersonSuccess}
                    />
                  </aside>
                </Col>
              )
          }
        </Row>
      </main>
    </div>
  );
}

export default ModalContent;
