import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AGENDOR_CHAT_URL } from '@/config';

const AgendorChatContext = createContext(undefined);

export const useAgendorChatContext = () => {
  const context = useContext(AgendorChatContext);

  if (context === undefined) {
    throw new Error('useAgendorChatContext must be used within AgendorChatProvider');
  }

  return context;
};

const agendorChatBaseUrl = `${AGENDOR_CHAT_URL}/app`;

export function AgendorChatProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(agendorChatBaseUrl);

  const openAgendorChat = (src) => {
    setIframeSrc(src);
    setIsOpen(true);
  };

  const closeAgendorChat = () => {
    setIsOpen(false);
    setIframeSrc(agendorChatBaseUrl);
  };

  return (
    <AgendorChatContext.Provider value={{ isOpen, iframeSrc, openAgendorChat, closeAgendorChat }}>
      {children}
    </AgendorChatContext.Provider>
  );
}

const propTypes = {
  children: PropTypes.node.isRequired
};

AgendorChatProvider.propTypes = propTypes;
