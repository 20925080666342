import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import {
  TextInput,
  ProductCategorySelect,
  CurrencyInput,
  Switch
} from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  isCreating: PropTypes.bool,
  product: PropTypes.object
};

const defaultProps = {
  show: false,
  onCancel: () => {},
  isCreating: true,
  product: {}
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .required('Por favor, informe o nome do produto ou serviço.'),
  price: Yup.number()
    .min(0, 'Preço deve ser positivo.')
    .max(999999999.99, 'Preço deve ser no máximo 999.999.999,99.')
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  name: '',
  product_category_id: '',
  code: '',
  price: '',
  active: true
};

function ProductModal({ show, onCancel, onSubmit, isCreating, product }) {
  const TITLE = `${isCreating ? 'Criar' : 'Editar'} um produto ou serviço`;

  const initialValues = useMemo(() => ({
    name: product?.name ?? '',
    code: product?.code ?? '',
    price: product?.price?.toString() ?? '',
    product_category_id: product?.category?.id ?? '',
    active: product?.active ?? true
  }), [product]);

  return (
    <Modal onHide={onCancel} size='lg' bodyClassName='p-8' show={show}>
      <div className='mb-8 text-center'>
        <div className='d-inline-block
          p-3
          rounded-circle
          bg-dark-gray
          text-white
          lh-0
          position-relative'
        >
          <Icon size='lg' name='product'></Icon>
        </div>
        <h2 className='mt-4'>{TITLE}</h2>
      </div>
      <Form
        name='create-product-form'
        validationSchema={schema}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <>
            <Row>
              <Col sm={12}>
                <FormField
                  as={TextInput}
                  name='name'
                  label='Nome'
                  autoComplete='off'
                  placeholder='Dê um nome para o produto ou serviço'
                  hint='Obrigatório'
                  maxLength={60}
                  variant='light'
                />
              </Col>

              <Col sm={12}>
                <FormField
                  as={ProductCategorySelect}
                  name='product_category_id'
                  label='Categoria'
                  autoComplete='off'
                  maxLength={60}
                  placeholder='Defina uma categoria'
                  variant='light'
                />
              </Col>

              <Col sm={6}>
                <FormField
                  as={CurrencyInput}
                  name='price'
                  label='Preço'
                  autoComplete='off'
                  placeholder='R$ 0,00'
                  variant='light'
                />
              </Col>

              <Col sm={6}>
                <FormField
                  as={TextInput}
                  name='code'
                  label='Código'
                  autoComplete='off'
                  placeholder='Código de referência'
                  maxLength={60}
                  variant='light'
                />
              </Col>

              <Col sm={12}>
                <div className='border border-1 rounded px-4 py-3'>
                  <FormField
                    as={Switch}
                    name='active'
                    label={
                      <>
                        <span className='fw-bold me-2 align-middle'>
                          Ativar para novos cadastros
                        </span>

                        <Tooltip
                          placement='bottom'
                          content={
                            `Se um produto ou serviço for inativado, ele deixará
                            de aparecer para novos negócios.`
                          }
                        >
                          <span className='d-inline-block align-middle'>
                            <Icon name='info' className='text-medium-gray' />
                          </span>
                        </Tooltip>
                      </>
                    }
                    className='text-start mb-0'
                  />
                </div>
              </Col>
            </Row>

            <div className='mt-6 d-flex justify-content-end'>
              <Button
                onClick={onCancel}
                variant='outline-dark-gray'
                className='me-3'
              >
                Cancelar
              </Button>

              <LoadingButton
                isLoading={isSubmitting}
                type='submit'
              >
                Salvar
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

ProductModal.propTypes = propTypes;
ProductModal.defaultProps = defaultProps;

export default ProductModal;
