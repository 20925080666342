import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { TextArea, RadioButton } from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';
import storage from '@/lib/storage';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  id: PropTypes.number,
  entityType: PropTypes.oneOf(['organization', 'person']).isRequired
};

const defaultProps = {
  show: false,
  onHide: () => {},
  onConfirm: () => {},
  id: null
};

const schema = Yup.object().shape({
  answer: Yup.string().required('Por favor, selecione uma alternativa.'),
  feedback: Yup.string().required('Pergunta obrigatória.')
});

const defaultValues = {
  answer: '',
  feedback: ''
};

function FeedbackToLegacyModal({ show, onHide, onConfirm, entityType }) {
  const { user } = useAuth();
  const tracker = useTracking();

  const radioOptions = [
    'Não encontrei a informação que preciso',
    'Não consigo diferenciar da Ficha do Negócio',
    'A nova tem muita informação',
    'Estou habituado com a outra versão',
    'Outro'
  ];

  const handleFeedbackSubmit = (form) => {
    const entityName = entityType === 'organization' ? 'Empresa' : 'Pessoa';

    tracker.trackBackToLegacyConfirmed({
      user,
      feedback: form.feedback,
      answer: radioOptions[Number(form.answer)],
      screen: 'Ficha',
      entity: entityName
    });

    storage.set('lastFeedbackDate_Feedback', new Date().toISOString());

    onConfirm();
  };

  return (
    <Modal bodyClassName='p-8' show={show} size='lg' backdrop='static' onHide={onHide}>
      <h3 className='mb-3'>Por que você está voltando para a versão antiga 🥹?</h3>
      <Form
        name='feedbackForm'
        validationSchema={schema}
        defaultValues={defaultValues}
        onSubmit={handleFeedbackSubmit}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            {
              radioOptions.map((label, index) => (
                <FormField
                  as={RadioButton}
                  id={`options_${index}`}
                  key={`options_${index}`}
                  radioValue={index.toString()}
                  name='answer'
                  label={<div className='ms-2 mt-1'>{label}</div>}
                />
              ))
            }
            {
              values.answer && (
                <FormField
                  as={TextArea}
                  name='feedback'
                  label={
                    values.answer === '0'
                      ? 'Qual informação você não encontrou?'
                      : 'Você pode dar mais detalhes? Isso vai nos ajudar a melhorar'
                  }
                  placeholder='Digite aqui…'
                />
              )
            }
            <div className='d-flex mt-3 justify-content-end'>
              <Button className='me-3' variant='danger' onClick={onHide}>Cancelar</Button>
              <LoadingButton
                variant='light'
                onClick={handleSubmit}
                isLoading={isSubmitting}
                loadingText='Carregando…'
                disabled={!values.answer}
              >
                Voltar para a versão antiga
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

FeedbackToLegacyModal.propTypes = propTypes;
FeedbackToLegacyModal.defaultProps = defaultProps;

export default FeedbackToLegacyModal;
