import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '@/components/Icon';
import Popover from '@/components/Popover';

import { toCurrencyString } from '@/number';

const propTypes = {
  remainingDays: PropTypes.number.isRequired,
  planFullName: PropTypes.string.isRequired,
  currentPlanFullName: PropTypes.string.isRequired,
  valuePerMonth: PropTypes.number.isRequired,
  currentValuePerMonth: PropTypes.number.isRequired,
  diffValuePerMonth: PropTypes.number.isRequired,
  diffValuePerDay: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  valueWithoutCustomDiscount: PropTypes.number.isRequired,
  discounts: PropTypes.array
};

const defaultProps = {
  discounts: []
};

function ProratedSummary(props) {
  const {
    remainingDays,
    planFullName,
    currentPlanFullName,
    valuePerMonth,
    currentValuePerMonth,
    diffValuePerMonth,
    diffValuePerDay,
    discounts,
    currentDiscounts,
    prorateDiscounts,
    periodicityText,
    valueWithoutCustomDiscount,
    value
  } = props;

  const couponDiscount = discounts.find(
    (discount) => discount.billingCoupon && discount.percentOff
  );

  return (
    <Popover
      content={() => (
        <div
          id='prorated-summary'
          className={
            classnames(
              'w-100',
              'text-small',
              'text-dark-gray'
            )
          }
        >
          <h4 className='mb-4'>Resumo do pagamento da pró-rata</h4>

          <div className='d-flex align-items-center mb-3'>
            <Icon className='text-body me-2' name='info' size='sm' />

            <div className='text-wrap word-wrap max-width-6'>
              Pró-rata é a diferença de valor entre os planos contratados,
              proporcional aos dias restantes no período atual.
            </div>
          </div>

          <RowContent
            title={
              <>
                <span className='text-body'>Atual</span>: Plano {currentPlanFullName}

                <DiscountsList discounts={currentDiscounts} periodicityText={periodicityText} />
              </>
            }
            content={`${toCurrencyString(currentValuePerMonth)}/mês`}
          />

          <RowContent
            title={
              <>
                <span className='text-body'>Novo</span>: Plano {planFullName}

                <DiscountsList discounts={prorateDiscounts} periodicityText={periodicityText} />
              </>
            }
            content={`${toCurrencyString(valuePerMonth)}/mês`}
          />

          <RowContent
            title='Diferença de valor'
            content={`${toCurrencyString(diffValuePerMonth)}/mês (30 dias)`}
          />

          <RowContent
            title='Valor por dia'
            content={`
              ${toCurrencyString(diffValuePerMonth)} / 30 = ${toCurrencyString(diffValuePerDay)}
            `}
          />

          <RowContent
            title='Dias restantes no período atual'
            content={remainingDays}
          />

          <RowContent
            title={`Valor da Pró-rata para os ${remainingDays} dias restantes`}
            content={
              <>
                <>{remainingDays} * {toCurrencyString(diffValuePerDay)} = </>
                {toCurrencyString(valueWithoutCustomDiscount)}
              </>
            }
          />

          {
            couponDiscount &&
            <RowContent
              title={
                <>
                  Valor com desconto
                  <DiscountsList discounts={[couponDiscount]} />
                </>
              }
              content={
                <>
                  <s>{toCurrencyString(valueWithoutCustomDiscount)}</s> {toCurrencyString(value)}
                </>
              }
            />
          }
        </div>
      )}
      className='mw-100'
      contentClassName='px-3 py-4 bg-dark-light rounded-1'
      trigger={['click']}
      placement='left-start'
      rootClose
      rootCloseEvent='mousedown'
      flip
    >
      <div className='d-flex align-items-center'>
        <Icon className='cursor-pointer' name='info' size='sm' />
      </div>
    </Popover>
  );
}

function RowContent({ title, content }) {
  return (
    <div className='d-flex align-items-baseline justify-content-between'>
      <div className='fw-bold me-8'>{title}</div>

      <div className='ms-2'>{content}</div>
    </div>
  );
}

function DiscountsList({ discounts, periodicityText }) {
  const hasDiscount = (discounts || []).length > 0;

  if (!hasDiscount) {
    return null;
  }

  const renderDiscount = (discount) => {
    if (discount.valueOff) {
      return `-R$${discount.valueOff}`;
    }

    const periodText = discount.default ? ` ${periodicityText}` : '';

    return `-${discount.percentOff}%${periodText}`;
  };

  return (
    <span className='fw-normal'> (
      {
        discounts.map((discount, index) => (
          <span key={discount.id}>
            {Boolean(index) && ' '}

            {renderDiscount(discount)}
          </span>
        ))
      }
      )
    </span>
  );
}

ProratedSummary.propTypes = propTypes;
ProratedSummary.defaultProps = defaultProps;

export default ProratedSummary;
